import { Avatar, Box, Button, Flex, FlexProps, Grid, Group, Input, Space, Stack, Text, TextInput, Title } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import { MdOutlineSearch } from 'react-icons/md'
import { SubTitle } from '../../../components/takeoff-tokyo/SubTitle'
import { IconArrowForward } from '../../IconArrowForward'
import { TokyoLayout, TokyoLayoutLoader } from '../../../components/takeoff-tokyo/TokyoLayout'
import { useDebouncedState } from '@mantine/hooks'
import { useHistory } from 'react-router-dom'
import { QuickSearchCompaniesType } from '../../Dashboard/SearchBar'
import { createStartupClaim, getQuickSearch } from '../../../../store/takeoff-tokyo/startup-claim-atom'

function BadgeGray(props: FlexProps) {
  return (
    <Flex
      align={'center'}
      gap={4}
      px={8}
      py={4}
      fw={500}
      sx={{
        color: '#383839',
        border: '1px solid #484849',
        background: '#4848491F',
        borderRadius: '16px',
        fontSize: '10px',
      }}
      {...props}
    />
  )
}

export function FindYourStartup() {
  const history = useHistory()
  const [search, setSearch] = useDebouncedState('', 500, { leading: true })
  const [data, setData] = useState<QuickSearchCompaniesType[]>([])

  useEffect(() => {
    ;(async () => {
      const { companies } = await getQuickSearch({ query: search, from: 0, limit: 50 })
      setData(companies)
    })()
  }, [search])

  const handleClaim = async (id: string) => {
    const res = await createStartupClaim({ organization_id: id })
    history.push('/takeoff-tokyo/startups/edit-startup-profile')
  }

  if (!data) return <TokyoLayoutLoader />

  return (
    <TokyoLayout>
      <Title>Find your startup</Title>
      <Text>
        Find your startup in the list below and claim it to complete your profile and start connecting with investors. You can also use the search bar to
        quickly locate your company. Can’t find your startup? No problem! Just click the button below to create your company profile and enter your details.
        Ensure your information is accurate so investors can discover and connect with you easily. Let’s get started and maximize your opportunities at Takeoff
        Tokyo!
      </Text>
      <Text>
        以下のリストから貴社名を見つけ、選択してプロフィールを完成させましょう。投資家とのマッチングがすぐに始められます。検索バーを使用すると、貴社を素早く見つけることも可能です。
        該当するスタートアップ企業が見つからない場合もご安心ください。下のボタンをクリックして、新しく企業プロフィールを作成し、必要な情報を入力してください。正確な情報を登録することで、投資家がスムーズに貴社を発見し、つながることができます。
        Takeoff Tokyoで最大限のチャンスをつかみましょう！
      </Text>
      <TextInput
        radius={30}
        onChange={(event) => setSearch(event.currentTarget.value)}
        defaultValue={search}
        icon={<MdOutlineSearch size={24} color={'#383839'} />}
        label={'Search by name or domain name'}
        placeholder={'アレスグッド'}
      />
      <Input.Wrapper label={'Result:'}>
        <Stack spacing={24}>
          {data.map((item) => (
            <StartupClaimItem
              key={item.id}
              name={item.name}
              tag={item?.industry_tag}
              claimButton={
                <Button onClick={() => handleClaim(item.id)} variant={'outline'}>
                  Claim company
                </Button>
              }
              avatar={<Avatar src={logoUrl(item?.logo_id)} size={35} radius={'100%'} />}
            />
          ))}
        </Stack>
      </Input.Wrapper>
      <SubTitle>Can&apos;t find your startup?</SubTitle>
      <Box>
        <Button onClick={() => history.push('/takeoff-tokyo/startups/edit-startup-profile')}>
          Create company
          <IconArrowForward />
        </Button>
      </Box>
    </TokyoLayout>
  )
}

function StartupClaimItem({ avatar, name, tag, claimButton }: { name: string; tag?: string; claimButton: React.ReactNode; avatar?: React.ReactNode }) {
  return (
    <Grid align={'start'}>
      <Grid.Col span="content">
        {avatar}
        <Space w={14} />
      </Grid.Col>
      <Grid.Col span="auto">
        <Stack spacing={8}>
          {name ? (
            <Text size={14} fw={600} color={'black'}>
              {name}
            </Text>
          ) : null}
          <Group spacing="sm">{tag ? <BadgeGray>{tag}</BadgeGray> : null}</Group>
        </Stack>
      </Grid.Col>
      <Grid.Col span="content">{claimButton}</Grid.Col>
    </Grid>
  )
}

function logoUrl(logoId: string) {
  return `https://images.crunchbase.com/image/upload/${logoId}`
}
