import { atomWithRefresh } from 'jotai/utils'
import { currentUserAtom } from './current-user-atom'
import { BackendCall, callBackend } from '../../logic/Backend'

export const startupProfileAtom = atomWithRefresh(async (get) => {
  const user = await get(currentUserAtom)
  if (!user?.organization_id) return null

  return await editCompany(user.organization_id)
})

export type CompanyPayload = {
  id?: string
  name_jp?: string
  name?: string
  employee_count?: number
  employee_count_int4range?: string
  domain?: string
  industry_group?: string
  industry?: string
  sub_industry?: string
  founded_on_date?: string
  description_jp?: string
  description?: string
  latest_funding_type?: string
  amount_looking_to_raise_millions_jpy?: number
  logo_upload?: File
  country_iso_3166_1_alpha_3?: string
}

export type CompanyResponse = {
  id: string
  name: string
  name_jp: string
  description: string
  description_jp: string
  domain: string
  founded_on_date: string
  latest_funding_type: string
  industry_group: string
  industry?: string
  sub_industry?: string
  amount_looking_to_raise_millions_jpy: number
  logo_upload: {
    url: null | string
  }
  logo_id?: string | number
  employee_count?: number
  country_iso_3166_1_alpha_3?: string
}

export const editCompany: BackendCall<string, CompanyResponse> = async (id, abortSignal) => {
  return await callBackend('get', `companies/${id}/edit`, 200, undefined, (r) => r.json(), abortSignal)
}

const BASE_URL = process.env.REACT_APP_BACKEND_URL
const VALID_STATUS_CODES = [200, 201]

export async function createStartupProfile(body: CompanyPayload) {
  const formData = new FormData()

  Object.entries(body).forEach(([key]) => {
    formData.append(key, body[key])
  })
  const path = `companies`
  const res = await fetch(BASE_URL + path, {
    method: 'POST',
    credentials: 'include',
    body: formData,
  })

  if (VALID_STATUS_CODES.includes(res.status)) return res.json()
  throw new Error(res.statusText)
}

export async function updateStartupProfile(body: CompanyPayload) {
  const formData = new FormData()

  Object.entries(body).forEach(([key]) => {
    formData.append(key, body[key])
  })
  const path = `companies/${body.id}`
  const res = await fetch(BASE_URL + path, {
    method: 'PUT',
    credentials: 'include',
    body: formData,
  })

  if (VALID_STATUS_CODES.includes(res.status)) return res.json()
  throw new Error(res.statusText)
}

// export const createCompany: BackendCall<CompanyPayload, Record<string, string>> = async (body, abortSignal) => {
//   return await callBackend('post', 'companies', 201, body, (r) => r.json(), abortSignal)
// }

// export const updateCompany: BackendCall<CompanyPayload, Record<string, string>> = async (body, abortSignal) => {
//   return await callBackend('put', `companies/${body.id}`, 201, body, (r) => r.json(), abortSignal)
// }
