export const filterColumnValueTranslations = {
  column: {
    'Kurrant.ai Rating': 'Kurrant.ai 評価',
    'Your Rating': 'あなたの評価',
    'Pipeline Status': 'パイプラインステータス',
    'In Lists': 'リストに含まれる',
    'Recommended Score': '推奨スコア',
    'Created At': '作成日',
    'Combined Keyword Search': '組み合わせキーワード検索',
    Name: '名前',
    Domain: 'ドメイン',
    'Short Description': '短い説明',
    Description: '説明',
    'Founded In': '設立年',
    Country: '国',
    State: '州',
    City: '市',
    'Postal Code': '郵便番号',
    'Industry Group': '業界グループ',
    Industry: '業界',
    'Sub Industries': 'サブ業界',
    'Operating Status': '運営ステータス',
    'Company Type': '会社タイプ',
    'Employee Range': '従業員数',
    'LinkedIn Employees': 'LinkedIn 従業員数',
    'LinkedIn Employees Monthly Growth': 'LinkedIn 従業員数月次成長',
    'Estimated Sales Revenue': '推定売上高',
    'Estimated Sales Revenue Monthly Growth': '推定売上高月次成長',
    'Tech Spend': '技術開発費',
    'Instagram Followers': 'Instagram フォロワー数',
    'Instagram Followers Monthly Growth': 'Instagram フォロワー数月次成長',
    'LinkedIn Followers': 'LinkedIn フォロワー数',
    'LinkedIn Followers Monthly Growth': 'LinkedIn フォロワー数月次成長',
    'Total Assets': '総資産',
    Revenue: '売上高',
    'Net Income': '純利益',
    'Operating Income': '営業利益',
    'Total Funding': '総資金調達額',
    Investors: '投資家',
    'Latest Funding': '最新資金調達額',
    'Latest Funding In': '最新資金調達日',
    'Latest Funding Type': '最新資金調達タイプ',
    'Funding Rounds': '資金調達ラウンド数',
    'Exit Count': '退出数',
    LinkedIn: 'LinkedIn',
    Facebook: 'Facebook',
    Twitter: 'Twitter',
    Instagram: 'Instagram',
    Pinterest: 'Pinterest',
    Address: '住所',
    Email: 'メール',
    Phone: '電話',
    'Sales Target': '販売目標',
    'Revenue Stream': '収益ストリーム',
    Region: '地域',
    'Sub Region': 'サブ地域',
  },
}
