import { init } from '@rematch/core'
import listState from './listState'
import { Models } from '@rematch/core'
import industryState from './industryState'
import selectedNestedIndustryState from './selectedNestedIndustryState'
import totalCompanyCountState from './totalCompanyCountState'
import selectedCompanyState from './selectedCompanyState'
import companyNameColumnSizeState from './companyNameColumnSizeState'
import chatMessagesState from './chatMessagesState'
import chatLoadingState from './chatLoadingState'
import newsFilterState from './newsFilterState'
export interface RootModel extends Models<RootModel> {
  listState: typeof listState
  industryState: typeof industryState
  selectedNestedIndustryState: typeof selectedNestedIndustryState
  totalCompanyCountState: typeof totalCompanyCountState
  selectedCompanyState: typeof selectedCompanyState
  companyNameColumnSizeState: typeof companyNameColumnSizeState
  chatMessagesState: typeof chatMessagesState
  chatLoadingState: typeof chatLoadingState
  newsFilterState: typeof newsFilterState
}

export const models: RootModel = {
  listState,
  industryState,
  selectedNestedIndustryState,
  totalCompanyCountState,
  selectedCompanyState,
  companyNameColumnSizeState,
  chatMessagesState,
  chatLoadingState,
  newsFilterState,
}

// export default store
