import React, { useEffect, useState } from 'react'
import { Button, FileButton, Grid, Group, Image, Input, NumberInput, Select, Space, Stack, Switch, Text, Textarea, TextInput } from '@mantine/core'
import { MdCurrencyYen, MdOutlineGroup, MdOutlineLanguage } from 'react-icons/md'
import { YearPickerInput } from '@mantine/dates'
import { TokyoFormLayout } from '../../../components/takeoff-tokyo/TokyoFormLayout'
import { SubTitle } from '../../../components/takeoff-tokyo/SubTitle'
import { isNotEmpty, useForm } from '@mantine/form'
import { useAtom } from 'jotai'
import { industryDataAtom } from '../../../../store/takeoff-tokyo/industry-data-atom'
import { FUNDING_TYPES } from '../../../../resources/reference_data/funding_types'
import { useHistory } from 'react-router-dom'
import { currentUserAtom } from '../../../../store/takeoff-tokyo/current-user-atom'
import { CompanyPayload, createStartupProfile, startupProfileAtom, updateStartupProfile } from '../../../../store/takeoff-tokyo/startup-profile-atom'
import dayjs from 'dayjs'
import { showErrorToast, showSuccessToast } from '../../../../logic/Toaster'
import { availabilityAtom } from '../../../../store/takeoff-tokyo/availability-atom'
import { COUNTRIES_MAP } from '../../../../resources/reference_data/countries'

const COUNTRY_OPTIONS = Object.entries(COUNTRIES_MAP).map(([k, v]) => ({
  value: k,
  label: v.join(' '),
}))

const FUNDING_TYPE_OPTIONS = Object.entries(FUNDING_TYPES).map(([k, v]) => ({
  value: k,
  label: v,
}))

type FormValues = {
  name_jp: string
  name: string
  employee_count: number | ''
  domain: string
  industry_group: string
  industry?: string
  sub_industry?: string
  founded_on_date: Date | null
  description_jp: string
  description: string
  latest_funding_type: string
  amount_looking_to_raise_millions_jpy: number | ''
  country: string
}

export function EditStartupProfile() {
  const [file, setFile] = useState<File | null>(null)
  const [industryData] = useAtom(industryDataAtom)
  const [availability] = useAtom(availabilityAtom)
  const hasAvailability = availability?.merged_slots?.length > 0

  const history = useHistory()

  const [preview, setPreview] = useState<string | null>(null)
  useEffect(() => {
    if (file) {
      setPreview(URL.createObjectURL(file))
    }
  }, [file])
  // selected
  const [industryGroups, setIndustryGroups] = useState<string>() // string
  const [industriesMaps, setIndustryMaps] = useState<string>() // strings
  const [subIndustries, setSubIndustries] = useState<string>() // ids
  const form = useForm<FormValues>({
    initialValues: {
      name_jp: '',
      name: '',
      employee_count: '',
      domain: '',
      industry_group: '',
      industry: '',
      sub_industry: '',
      founded_on_date: null,
      description_jp: '',
      description: '',
      latest_funding_type: '',
      amount_looking_to_raise_millions_jpy: '',
      country: '',
    },
    validate: {
      name: isNotEmpty('required'),
      employee_count: isNotEmpty('required'),
      domain: isNotEmpty('required'),
      industry_group: isNotEmpty('required'),
      founded_on_date: isNotEmpty('required'),
      description: isNotEmpty('required'),
      sub_industry: industryGroups !== '' && industriesMaps !== '' && isNotEmpty('required'),
    },
  })

  useEffect(() => {
    setIndustryGroups(form.getInputProps('industry_group').value)
    setIndustryMaps(form.getInputProps('industry').value)
  }, [form.getInputProps('industry_group').value])

  // re-evaluate dependent options
  const industryMapOptions = industryData.industryMapOptions.filter((x) => x.group === industryGroups)
  const subIndustryOptions = industryData.subIndustryLabelOptions.filter((x) => x.group === industriesMaps)
  const handleIndustryGroupChange = (e) => {
    setIndustryGroups(e)
    form.setFieldValue('industry_group', e)
    setIndustryMaps('')
    form.setFieldValue('industry', '')
    setSubIndustries('')
    form.setFieldValue('sub_industry', '')
  }

  const handleIndustryChange = (e) => {
    setIndustryMaps(e)
    form.setFieldValue('industry', e)
    setSubIndustries('')
    form.setFieldValue('sub_industry', '')
  }

  const handleSubIndustryChange = (e) => {
    setSubIndustries(e)
    form.setFieldValue('sub_industry', e)
  }

  useEffect(() => {
    form.setFieldValue('industries', [industryGroups, subIndustries])
  }, [industryGroups, subIndustries])

  const [raisingFund, setRaisingFund] = useState(false)

  const [currentUser, refreshCurrentUser] = useAtom(currentUserAtom)
  useEffect(() => refreshCurrentUser(), [refreshCurrentUser])
  const isEditForm = Boolean(currentUser.organization_id)

  const [startupProfile, refreshStartupProfile] = useAtom(startupProfileAtom)
  useEffect(() => refreshStartupProfile(), [refreshStartupProfile])

  useEffect(() => {
    // prefill form
    if (startupProfile) {
      const sp = startupProfile
      const isRaisingFund = [sp.amount_looking_to_raise_millions_jpy, sp.latest_funding_type].every(Boolean)
      setRaisingFund(isRaisingFund)
      const prefillValues: Partial<FormValues> = {
        name: sp.name,
        name_jp: sp.name_jp,
        description: sp.description,
        description_jp: sp.description_jp,
        domain: sp.domain,
        industry_group: sp.industry_group,
        industry: sp.industry,
        sub_industry: sp.sub_industry,
        employee_count: sp.employee_count || '',
        founded_on_date: sp.founded_on_date ? dayjs(sp.founded_on_date).toDate() : null,
      }

      if (isRaisingFund)
        Object.assign(prefillValues, {
          amount_looking_to_raise_millions_jpy: sp.amount_looking_to_raise_millions_jpy || '',
          latest_funding_type: sp.latest_funding_type || '',
        })

      form.setValues(prefillValues)
    }
  }, [startupProfile, industryData])

  const [loadingButton, setLoadingButton] = useState(false)
  const onSubmit = async (values: FormValues) => {
    const payload: Partial<CompanyPayload> = {
      name: values.name,
      domain: values.domain,
      description: values.description,
      name_jp: values.name_jp,
      description_jp: values.description_jp,
      employee_count: values.employee_count || undefined,
      industry_group: values.industry_group,
      industry: values.industry || '',
      sub_industry: values.sub_industry || '', // send sub ind string instead of ID
      country_iso_3166_1_alpha_3: values.country,
      founded_on_date: dayjs(values.founded_on_date).format('YYYY-MM-DD'), // 2020 -> 2020-01-01
    }

    const fundingPayload: Pick<CompanyPayload, 'amount_looking_to_raise_millions_jpy' | 'latest_funding_type'> = {
      amount_looking_to_raise_millions_jpy: values.amount_looking_to_raise_millions_jpy || undefined,
      latest_funding_type: values.latest_funding_type,
    }
    if (raisingFund) Object.assign(payload, fundingPayload)
    if (file) Object.assign(payload, { logo_upload: file })

    if (isEditForm) {
      try {
        setLoadingButton(true)
        const { status_cd } = await updateStartupProfile({ ...payload, id: currentUser.organization_id })
        refreshCurrentUser()
        showSuccessToast(`Profile changes ${status_cd}`)
        setLoadingButton(false)
        hasAvailability ? history.push('/takeoff-tokyo/startups/welcome-back') : history.push('/takeoff-tokyo/startups/thank-for-update')
      } catch (e) {
        showErrorToast(`Update profile failed, ${e.message}`)
        setLoadingButton(false)
      }
    } else {
      // create profile
      // startup user is onboarding
      try {
        setLoadingButton(true)
        const res = await createStartupProfile(payload)
        setLoadingButton(false)
        hasAvailability ? history.push('/takeoff-tokyo/startups/welcome-back') : history.push('/takeoff-tokyo/startups/thank-for-update')
      } catch (e) {
        showErrorToast(`Create profile failed, ${e.message}`)
        setLoadingButton(false)
      }
    }
  }

  return (
    <TokyoFormLayout>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <SubTitle>Edit your startup profile</SubTitle>

        <Space h={24} />

        <Input.Wrapper label={'Company Logo'}>
          <Group spacing={16}>
            {file ? (
              <Stack spacing={4}>
                <Image src={preview} maw={140} />
                <Text size="sm" align="center" mt="sm">
                  {file.name}
                </Text>
              </Stack>
            ) : (
              <Image src={startupProfile?.logo_upload?.url} maw={140} />
            )}
            <FileButton onChange={setFile} accept="image/png,image/jpeg,image/jpg,image/gif">
              {(props) => (
                <Button variant={'outline'} {...props}>
                  Update
                </Button>
              )}
            </FileButton>
          </Group>
        </Input.Wrapper>

        <Space h={24} />

        <Grid grow>
          <Grid.Col md={6}>
            <Stack spacing={24}>
              <TextInput label={'Name in Japanese'} {...form.getInputProps('name_jp')} />
              <NumberInput icon={<MdOutlineGroup color={'#484849'} size={24} />} label={'Number of employees'} {...form.getInputProps('employee_count')} />
              <Select
                searchable
                data={industryData.industryGroupOptions}
                label="Industry group"
                placeholder=""
                size="lg"
                {...form.getInputProps('industry_group')}
                onChange={handleIndustryGroupChange}
              />
              <Select
                searchable
                data={industryMapOptions}
                label="Industry"
                placeholder=""
                size="lg"
                {...form.getInputProps('industry')}
                onChange={handleIndustryChange}
              />
              <Select
                searchable
                data={subIndustryOptions}
                label="Sub industry"
                placeholder=""
                size="lg"
                {...form.getInputProps('sub_industry')}
                onChange={handleSubIndustryChange}
              />
              <Select searchable data={COUNTRY_OPTIONS} label="Country" placeholder="" size="lg" {...form.getInputProps('country')} />
              {/*<Input.Wrapper label={'Description in Japanese'}>*/}
              {/*  <RichTextDemo onChange={form.getInputProps('description_jp').onChange} value={form.getInputProps('description_jp').value} />*/}
              {/*</Input.Wrapper>*/}
              <Textarea label={'Description in Japanese'} minRows={7} maxRows={7} {...form.getInputProps('description_jp')} />
            </Stack>
          </Grid.Col>
          <Grid.Col md={6}>
            <Stack spacing={24}>
              <TextInput label={'Name in English'} {...form.getInputProps('name')} />
              <TextInput icon={<MdOutlineLanguage color={'#484849'} size={24} />} label={'Website'} {...form.getInputProps('domain')} />
              <YearPickerInput label={'Founded in'} placeholder={'Pick a year'} {...form.getInputProps('founded_on_date')} />
              {/*<Input.Wrapper label={'Description in English'}>*/}
              {/*  <RichTextDemo onChange={form.getInputProps('description').onChange} value={form.getInputProps('description').value} />*/}
              {/*</Input.Wrapper>*/}
              <Textarea label={'Description in English'} minRows={7} maxRows={7} {...form.getInputProps('description')} />
            </Stack>
          </Grid.Col>
        </Grid>

        <Space h={24} />

        <Group>
          <Switch
            size="lg"
            checked={raisingFund}
            onChange={(e) => {
              setRaisingFund(e.currentTarget.checked)
            }}
          />
          <Text>Are you raising in the next three months?</Text>
        </Group>

        <Space h={24} />

        {raisingFund ? (
          <Grid grow>
            <Grid.Col md={6}>
              <Select searchable placeholder={''} label={'Fund Raise Stage'} data={FUNDING_TYPE_OPTIONS} {...form.getInputProps('latest_funding_type')} />
            </Grid.Col>
            <Grid.Col md={6}>
              <NumberInput
                label={'Amount looking to raise in millions of JPY'}
                size={'lg'}
                placeholder="100"
                icon={<MdCurrencyYen color={'#484849'} size={24} />}
                {...form.getInputProps('amount_looking_to_raise_millions_jpy')}
              />
            </Grid.Col>
          </Grid>
        ) : null}

        <Space h={24} />
        <Button loading={loadingButton} type="submit">
          Submit
        </Button>
      </form>
    </TokyoFormLayout>
  )
}
