import React, { FC, useEffect, useState } from 'react'
import styles from './index.module.scss'
import * as Backend from '../../../logic/Backend'
import { CompanyData, companyPage, getNews, Indexed } from '../../../logic/Backend'
import * as Routes from '../../../logic/Routes'
import { hrefForId, useQueryParams } from '../../../logic/Routes'
import { companyLink, internationalUnits, linkedinUrlNormalization, withoutHighlight } from '../../../logic/ValueFormatters'
import { TopBar } from '../../components/TopBar'
import { Cache, Set } from '../Standard'
import { BreadcrumbProps } from '@blueprintjs/core'
import { useLocation } from 'react-router'
import RightSidebar from './RightSidebar'
import { InsightsDataType } from './Insights'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { SimilarCompaniesType } from './SimilarCompanies'
import { Header } from './CompanyDetailComponents/Header/Header'
import { Tabs } from './CompanyDetailComponents/Tabs/Tabs'
import { CompanyBody, TabFragment } from './CompanyDetailComponents/CompanyBody/CompanyBody'
import News from '../Dashboard/News'
import NavbarButtons from './NavbarButtons'
import { KeyContactsCard } from './CompanyDetailComponents/KeyContactsCard/KeyContactsCard'
import CompanyNotes from './CompanyNotes'
import { InsightsCard } from './CompanyDetailComponents/InsightsCard'
import { DescriptionCard } from './CompanyDetailComponents/DescriptionCard/DescriptionCard'
import { LinkedinCard } from './CompanyDetailComponents/LinkedinCard/LinkedinCard'
import InstagramCard from './CompanyDetailComponents/InstagramCard/InstagramCard'
import { LineChart } from '../../../stories/LineChart'

export interface CompanyPageProps {
  id: Indexed['id']
  cache?: Cache
  setCache: Set<Cache | undefined>
}

const CompanyPage: FC<CompanyPageProps & WithNamespaces & IncludeT> = (props) => {
  const [company, setCompany] = useState(props.cache?.doubleClickedCompany)
  const [news, setNews] = useState<{ news: Backend.INewsItem[]; from: number; to: number; total_count: number }>()
  const [loadingNews, setLoadingNews] = useState(false)
  const [similarCompanies, setSimilarCompanies] = useState<(CompanyData & SimilarCompaniesType)[] | undefined>()
  const { focusedListId, focusedListName } = useQueryParams(['focusedListId', 'focusedListName'])
  const localization = localStorage.getItem('language')
  const companyName = withoutHighlight(localization === 'jp' && company?.name_jp ? company?.name_jp : company?.name)
  const [selected, setSelected] = useState('Insights')

  useEffect(() => {
    const getCompany = async () => {
      if (!company) {
        setCompany(await companyPage(props.id))
      }
    }
    const getCompanyNews = async () => {
      if (company) {
        setLoadingNews(true)
        // to do: add paginiation (separated from dashboard pagination)
        setNews(await getNews({ id: props.id, from: 0 }))
      }
      setLoadingNews(false)
    }
    getCompany()
    getCompanyNews()
  }, [company])
  const hash = useLocation().hash as TabFragment
  const { t } = props
  const companyBreadcrumb = (): BreadcrumbProps => {
    return {
      href: hrefForId(Routes.company, props.id),
      text: companyName,
      current: true,
    }
  }
  const listBreadcrumb = (focusedListId: string): BreadcrumbProps => {
    return {
      href: hrefForId(Routes.list, focusedListId),
      text: focusedListName,
    }
  }

  const breadcrumbs: BreadcrumbProps[] = focusedListId
    ? [Routes.lists, listBreadcrumb(focusedListId), companyBreadcrumb()]
    : [Routes.companies, companyBreadcrumb()]

  useEffect(() => {
    ;(async () => {
      if (company === undefined) {
        setCompany(await Backend.companyPage(props.id))
      }
      if (similarCompanies === undefined) {
        setSimilarCompanies(await Backend.similarCompanies(props.id))
      }
    })()
  }, [])

  const companyFormattedAddress = () => {
    if (company?.address == null) {
      return 'n/a'
    }
    return [company?.address, company?.city, company?.state, company?.postal_code].filter((el) => el != null).join(', ')
  }

  const linkedinUrl = linkedinUrlNormalization(company?.linkedin_url)
  const link = companyLink(company?.homepage, company?.domain)
  const pipelineStatus = company?.pipeline_status
  const organizationId = company?.id || null

  const insightsData: InsightsDataType = {
    sales_target: company?.sales_target_type,
    revenue_stream: company?.revenue_stream_type,
    industries: company?.industries,
    region: company?.region,
    sub_region: company?.sub_region,
  }

  const handleSectionClick = (section: string) => {
    const sectionElement = document.getElementById(section)
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    setSelected(section)
  }

  const shouldDisplayLIData = company?.li_name || company?.li_employee_list
  const shouldDisplayIG = Boolean(
    company?.ig_username ||
      company?.ig_business_category_name ||
      company?.ig_full_name ||
      company?.ig_is_verified ||
      company?.ig_posts_count_integer ||
      company?.ig_followers_count_integer ||
      company?.ig_following_count_integer ||
      company?.ig_biography ||
      company?.ig_external_url ||
      company?.ig_latest_9_posts_max_likes_date
  )
  const hasNonNullEmployeeCount = company?.li_employee_count_latest_6_months?.some((value) => value !== null)

  return (
    <div className={styles.detailsPagecontainer}>
      <div className={styles.detailsPageMainContent}>
        <TopBar breadcrumbs={breadcrumbs}>
          <NavbarButtons sections={[t('Notes'), t('Insights'), t('News')]} onSectionClick={handleSectionClick} selected={selected} />
        </TopBar>
        <div className={styles.detailsPageContentWrapper}>
          <Header
            company={company}
            companyName={companyName}
            link={link}
            linkedinUrl={linkedinUrl}
            organizationId={organizationId}
            pipelineStatus={pipelineStatus}
            t={t}
          />
          <CompanyNotes companyId={props.id} t={t} />
          <InsightsCard company={company} t={t} companyFormattedAddress={companyFormattedAddress} />
          <DescriptionCard company={company} t={t} localization={localization} />
          {news?.news.length > 0 && (
            <div className={styles.companyPageNewsContainer} id="News">
              <News news={news} />
            </div>
          )}
          {shouldDisplayLIData && <LinkedinCard company={company} t={t} localization={localization} linkedinUrl={linkedinUrl} />}
          {company?.people?.length && (
            <>
              <div className={styles.employeesSection}>
                <div className={styles.employeeGrowth}>
                  <h2>
                    {internationalUnits(company?.li_employee_count_integer, 1, '', '')} {t('Employees')}
                  </h2>
                  <KeyContactsCard company={company} />
                </div>
                {hasNonNullEmployeeCount && (
                  <div className={styles.employeeGrowthChartContainer}>
                    <h2>{t('Employee Growth')}</h2>
                    <div className={styles.employeeGrowthChart}>
                      <LineChart label={t('Monthly Growth')} values={company?.li_employee_count_latest_6_months}></LineChart>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {shouldDisplayIG && <InstagramCard company={company} />}
          {/* <Latest6MonthsChart label={' '} values={company?.bw_revenue_latest_6_months}></Latest6MonthsChart> */}
        </div>
      </div>
      <RightSidebar api={props.cache?.api} company={company} />
    </div>
  )
}

export default withNamespaces()(CompanyPage)
