import { atomWithRefresh } from 'jotai/utils'
import { atom } from 'jotai'
import { currentUserAtom } from './current-user-atom'
import { BackendCall, callBackend } from '../../logic/Backend'

type StartupRecommendation = {
  id: string
  organization: {
    name?: string
    short_description: string
    short_description_jp: string
    description: string
    description_jp: string
    domain: 'EkoheCreative.com'
    industry_groups: 'Education'
    logo_upload: {
      url: string
    }
    logo_id: string
  }
  recommendation_score: string | number
}
export const startupRecommendationAtom = atomWithRefresh(async (get) => {
  const user = await get(currentUserAtom)
  if (!user) return null
  return await getOrganizationsRecommendations()
})

export const startupRecommendationIdsAtom = atom(async (get) => {
  const items = await get(startupRecommendationAtom)
  return items.map((item) => item.id)
})

export const getOrganizationsRecommendations: BackendCall<undefined, StartupRecommendation[]> = async (_, abortSignal) => {
  return await callBackend('get', 'organizations_recommendations', 200, undefined, (r) => r.json(), abortSignal)
}

export type ShowStartupRecommendation = {
  id: string
  organization: {
    id: string
    name?: string
    short_description?: null | string
    short_description_jp?: null | string
    description?: null | string
    description_jp?: null | string
    domain?: string
    industry_groups?: string
    founded_on_date?: null | string
    user_name?: string
    employee_count?: null | string
    logo_upload?: {
      url?: null | string
    }
    logo_id?: null | string
  }
  recommendation_score: null | string
}

export const showOrganizationsRecommendation: BackendCall<string, ShowStartupRecommendation> = async (id, abortSignal) => {
  return await callBackend('get', `organizations_recommendations/${id}`, 200, undefined, (r) => r.json(), abortSignal)
}

type ReviewRecommendationPayload = {
  user_id: string
  organization_id: string
  accepted: boolean
  refused: boolean
}
export const reviewRecommendation = async (payload: ReviewRecommendationPayload) => {
  return await callBackend('post', 'organizations_recommendations/update_recommendation_status', 200, payload, (r) => r.json())
}
