import { Anchor, Button, Center, Checkbox, PasswordInput, PinInput, Space, Text, Title } from '@mantine/core'
import React from 'react'
import { GoVerified } from 'react-icons/go'
import { TokyoLayout } from '../../../components/takeoff-tokyo/TokyoLayout'
import { useHistory } from 'react-router-dom'

export function VerifyEmail() {
  const history = useHistory()
  return (
    <TokyoLayout>
      <Space h="lg" />
      <Title>Nice to meet you!</Title>
      <Space h={8} />
      <Text size="md">We just sent you an email with a code. Please input the code below to verify your identity.</Text>
      <Space h={40} />
      <Center>
        <PinInput size="lg" placeholder="●" />
      </Center>
      <Space h={40} />
      <Center px={28}>
        <Button size="lg" fullWidth={true}>
          <IconVerify />
          Verify
        </Button>
      </Center>
      <Space h={40} />
      <PasswordInput label="Password" placeholder="james@some.vc" />

      <Space h={24} />
      <Checkbox
        defaultChecked
        label={
          <Text size="md">
            By signing up, I agree to Kurrant.ai’s
            <Anchor c={'brand'} px={'.5ex'} fw={600}>
              Terms of Use
            </Anchor>
            and
            <Anchor c={'brand'} px={'.5ex'} fw={600}>
              Privacy Policy
            </Anchor>
          </Text>
        }
      />
      <Space h={48} />
      <Button size="lg" fullWidth onClick={() => history.push('/investors/create-account')}>
        Create Account
      </Button>
    </TokyoLayout>
  )
}

function IconVerify() {
  return (
    <Center mx={'.6ex'}>
      <GoVerified size={18} strokeWidth={1} />
    </Center>
  )
}
