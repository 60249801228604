import { Badge, Box, Button, Flex, Image, Space, Stack, Text, Title } from '@mantine/core'
import { SubTitle } from '../../../components/takeoff-tokyo/SubTitle'
import React, { useEffect, useState } from 'react'
import { TokyoLayout, TokyoLayoutLoader } from '../../../components/takeoff-tokyo/TokyoLayout'
import { BackButton } from '../../../components/takeoff-tokyo/BackButton'
import { useHistory } from 'react-router-dom'
import { useAtom } from 'jotai'
import {
  reviewRecommendation,
  showOrganizationsRecommendation,
  ShowStartupRecommendation,
  startupRecommendationIdsAtom,
} from '../../../../store/takeoff-tokyo/startup-recommendation-atom'
import { useCounter } from '@mantine/hooks'
import dayjs from 'dayjs'
import { currentUserAtom } from '../../../../store/takeoff-tokyo/current-user-atom'

export function ReviewRecommendation() {
  const history = useHistory()
  const [currentUser] = useAtom(currentUserAtom)

  const [startupIds] = useAtom(startupRecommendationIdsAtom)
  const [count, handlers] = useCounter(0, { min: 0, max: startupIds.length - 1 })

  const [data, setData] = useState<ShowStartupRecommendation>(null)

  useEffect(() => {
    ;(async () => {
      if (startupIds && startupIds.length > 0) {
        const res = await showOrganizationsRecommendation(startupIds[count])
        setData(res)
      }
    })()
  }, [startupIds, count])

  if (!data) return <TokyoLayoutLoader />

  const { recommendation_score, organization } = data
  const { id, founded_on_date, industry_groups, name, logo_upload, user_name, description, employee_count } = organization
  return (
    <TokyoLayout>
      {/*<Text>{data.id}</Text>*/}
      <BackButton onClick={() => history.push('/takeoff-tokyo/investors/welcome-back')} />
      <Box>
        <Image maw={242} src={logo_upload?.url ?? ''} />
      </Box>
      <Title>{name ?? ''}</Title>
      <Flex gap={16} align={'center'}>
        <Badge
          sx={{
            textTransform: 'none',
            height: '38px',
            background: 'linear-gradient(90deg, rgba(211, 246, 255, 0.8) 0%, rgba(229, 216, 255, 0.8) 50.5%, rgba(228, 229, 255, 0.8) 100%)',
          }}
          px={16}
          size={'xl'}
          variant="gradient"
        >
          <Flex gap={8}>
            <Text fs={'16px'} fw={600} color={'#484849'}>
              {recommendation_score}%
            </Text>
            <Text fs={'16px'} fw={400} color={'#484849'}>
              match
            </Text>
          </Flex>
        </Badge>

        <Text fs={'16px'} fw={600} color={'#484849'}>
          {count + 1} out of {startupIds.length} companies
        </Text>
      </Flex>

      <Flex gap={8} wrap={'wrap'}>
        <Box component={'dl'} w={167}>
          <Stack spacing={4}>
            <Text component={'dt'}>Founded in:</Text>
            <Text component={'dd'} ml={0} fw={600}>
              {founded_on_date ? dayjs(founded_on_date).format('YYYY') : ''}
            </Text>
          </Stack>
        </Box>
        <Box component={'dl'} w={167}>
          <Stack spacing={4}>
            <Text component={'dt'}>Employee count:</Text>
            <Text component={'dd'} ml={0} fw={600}>
              {employee_count ?? ''}
            </Text>
          </Stack>
        </Box>
        <Box component={'dl'} w={167}>
          <Stack spacing={4}>
            <Text component={'dt'}>Industry:</Text>
            <Text component={'dd'} ml={0} fw={600}>
              {industry_groups ?? ''}
            </Text>
          </Stack>
        </Box>
      </Flex>

      <Text>{description}</Text>

      <SubTitle>Meet with {user_name} ? </SubTitle>
      <Space h={64 - 24} />
      <Flex justify={'space-between'}>
        <Button
          variant={'outline'}
          miw={100}
          onClick={async () => {
            await reviewRecommendation({ user_id: currentUser.id, accepted: false, refused: true, organization_id: id })
            count === startupIds.length - 1 ? history.push('/takeoff-tokyo/investors/welcome-back') : handlers.increment()
          }}
        >
          No
        </Button>
        <Button
          miw={100}
          onClick={async () => {
            await reviewRecommendation({ user_id: currentUser.id, accepted: true, refused: false, organization_id: id })
            count === startupIds.length - 1 ? history.push('/takeoff-tokyo/investors/welcome-back') : handlers.increment()
          }}
        >
          Yes
        </Button>
      </Flex>
    </TokyoLayout>
  )
}
