import { Box, Button, Center, Input, SimpleGrid, Space, Stack, Text, Title } from '@mantine/core'
import { SubTitle } from '../../../components/takeoff-tokyo/SubTitle'
import { Select } from '@mantine/core' // Import Select instead of TimeInput
import React, { useEffect, useState } from 'react'
import { TokyoLayout } from '../../../components/takeoff-tokyo/TokyoLayout'
import { useForm } from '@mantine/form'
import { randomId } from '@mantine/hooks'
import { useAtom } from 'jotai'
import { availabilityAtom, AvailabilitySlotsPayload, createAvailabilitySlots, updateAvailabilitySlots } from '../../../../store/takeoff-tokyo/availability-atom'
import { currentUserAtom } from '../../../../store/takeoff-tokyo/current-user-atom'
import dayjs from 'dayjs'
import { groupBy } from 'lodash'
import { showErrorToast, showSuccessToast } from '../../../../logic/Toaster'
import { useHistory } from 'react-router-dom'
import { CloseButton } from '@mantine/core'

// Generate 15-minute interval options (09:00 to 19:00)
const timeOptions = []
for (let hour = 9; hour < 20; hour++) {
  for (let minute = 0; minute < 60; minute += 15) {
    const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
    timeOptions.push({ value: time, label: time })
  }
}

type TimeRangeItem = {
  from: string // format: '00:00'
  to: string // format: '00:00'
  key: string // keep track of form list item
}

type FormValues = {
  march25: TimeRangeItem[]
  march26: TimeRangeItem[]
}

const is15MinBased = (hh_mm: string) => {
  const [, mm] = hh_mm.split(':')
  return ['00', '15', '30', '45'].includes(mm)
}

const timeFormatValidator = (value: string) => {
  if (!value || value.length < 5) return 'required'
  if (!is15MinBased(value)) return '15 min based'
  return null
}

export function Availability() {
  const [availability, refreshAvailability] = useAtom(availabilityAtom)
  useEffect(() => refreshAvailability(), [])

  const form = useForm<FormValues>({
    initialValues: {
      march25: [],
      march26: [],
    },
    validate: {
      march25: {
        from: timeFormatValidator,
        to: timeFormatValidator,
      },
      march26: {
        from: timeFormatValidator,
        to: timeFormatValidator,
      },
    },
  })

  useEffect(() => {
    if (availability) {
      const { merged_slots } = availability
      const slotGroup = groupBy(merged_slots, (s) => parseDate(s[0]))

      const parseGroup = (groups: [string, string][]) =>
        groups.map((time_range) => {
          const [f, t] = time_range
          return {
            from: parseTime(f),
            to: parseTime(t),
            key: randomId(),
          }
        })

      if (slotGroup['25']) {
        form.setFieldValue('march25', parseGroup(slotGroup['25']))
      }
      if (slotGroup['26']) {
        form.setFieldValue('march26', parseGroup(slotGroup['26']))
      }
    }
  }, [availability])

  const [currentUser] = useAtom(currentUserAtom)
  const history = useHistory()
  const [loadingButton, setLoadingButton] = useState(false)

  const onSubmit = form.onSubmit(async (values) => {
    const { march25, march26 } = values

    const validPayload = (from: string, to: string) => dayjs(to) > dayjs(from)

    const payloadMarch25: AvailabilitySlotsPayload['time_ranges'] = march25.map((fromTo) => {
      const { from, to } = fromTo
      return {
        time_from: formatTime(from, '25'),
        time_to: formatTime(to, '25'),
      }
    })

    const payloadMarch26: AvailabilitySlotsPayload['time_ranges'] = march26.map((fromTo) => {
      const { from, to } = fromTo
      return {
        time_from: formatTime(from, '26'),
        time_to: formatTime(to, '26'),
      }
    })

    const payloads = [...payloadMarch25, ...payloadMarch26].filter(({ time_from, time_to }) => validPayload(time_from, time_to))

    try {
      setLoadingButton(true)
      const isEditing = availability && availability.merged_slots.length > 0
      const res = isEditing
        ? await updateAvailabilitySlots({ user_id: currentUser.id, time_ranges: payloads })
        : await createAvailabilitySlots({ user_id: currentUser.id, time_ranges: payloads })
      setLoadingButton(false)
      showSuccessToast(res.message)
      history.push('/takeoff-tokyo/investors/welcome-back')
    } catch (e) {
      showErrorToast(e.message)
      // Handle error
    }
  })

  return (
    <TokyoLayout>
      <Space h={24} />
      <form onSubmit={onSubmit}>
        <Stack>
          <Title>When are you available?</Title>
          <Text>
            Select your available time slots, and we’ll work to schedule meetings with promising startups in the networking area. The more availability you
            provide, the higher your chances of connecting with the right founders. Set your schedule now to make the most of Takeoff Tokyo!
          </Text>
          <Text>
            利用可能な時間枠を選択してください。私たちがネットワーキングエリアで有望なスタートアップとのミーティングを調整します。
            多くの時間枠を提供するほど、適切な創業者とつながるチャンスが高まります。 Takeoff Tokyo
            を最大限に活用するために、今すぐスケジュールを設定しましょう！
          </Text>
          <SubTitle>March, 25th 2025</SubTitle>
          <Input.Wrapper label={'Available from'}>
            {form.values.march25.map((item, index) => (
              <SimpleGrid key={item.key} cols={4} mb={24}>
                <Select
                  data={timeOptions}
                  value={item.from}
                  onChange={(value) => form.setFieldValue(`march25.${index}.from`, value)}
                  error={form.errors[`march25.${index}.from`]}
                  placeholder="HH:mm"
                  searchable
                />
                <Center>
                  <Text>to</Text>
                </Center>
                <Select
                  data={timeOptions}
                  value={item.to}
                  onChange={(value) => form.setFieldValue(`march25.${index}.to`, value)}
                  error={form.errors[`march25.${index}.to`]}
                  placeholder="HH:mm"
                  searchable
                />
                <Center>
                  <CloseButton
                    onClick={() => {
                      form.removeListItem('march25', index)
                    }}
                  />
                </Center>
              </SimpleGrid>
            ))}
          </Input.Wrapper>
          <Box mt={-24}>
            <Button variant="outline" size="lg" onClick={() => form.insertListItem('march25', { from: '', to: '', key: randomId() })}>
              Add availability +
            </Button>
          </Box>

          <SubTitle>March, 26th 2025</SubTitle>
          <Input.Wrapper label={'Available from'}>
            {form.values.march26.map((item, index) => (
              <SimpleGrid key={item.key} cols={4} mb={24}>
                <Select
                  data={timeOptions}
                  value={item.from}
                  onChange={(value) => form.setFieldValue(`march26.${index}.from`, value)}
                  error={form.errors[`march26.${index}.from`]}
                  placeholder="HH:mm"
                  searchable
                />
                <Center>
                  <Text>to</Text>
                </Center>
                <Select
                  data={timeOptions}
                  value={item.to}
                  onChange={(value) => form.setFieldValue(`march26.${index}.to`, value)}
                  error={form.errors[`march26.${index}.to`]}
                  placeholder="HH:mm"
                  searchable
                />
                <Center>
                  <CloseButton
                    onClick={() => {
                      form.removeListItem('march26', index)
                    }}
                  />
                </Center>
              </SimpleGrid>
            ))}
          </Input.Wrapper>
          <Box mt={-24}>
            <Button variant="outline" size="lg" onClick={() => form.insertListItem('march26', { from: '', to: '', key: randomId() })}>
              Add availability +
            </Button>
          </Box>

          <Button loading={loadingButton} type="submit">
            Complete
          </Button>
        </Stack>
      </form>
    </TokyoLayout>
  )
}

function formatTime(timeStr: string, day: '25' | '26') {
  return `2025-03-${day} ${timeStr}`
}

function parseTime(dateTimeStr: string) {
  const [_, timePart] = dateTimeStr.split('T')
  const [HH, mm] = timePart.split(':')
  return [HH, mm].join(':')
}

function parseDate(dateTimeStr: string) {
  const [datePart] = dateTimeStr.split('T')
  const [_yyyy, _mm, dd] = datePart.split('-')
  return dd
}
