import React, { useEffect, useState } from 'react'
import { Button, MultiSelect, Stack, Text, Title } from '@mantine/core'
import { SubTitle } from '../../../../components/takeoff-tokyo/SubTitle'
import { TokyoLayout } from '../../../../components/takeoff-tokyo/TokyoLayout'
import { WeightInput } from '../../../../components/takeoff-tokyo/WeightInput'
import { useHistory } from 'react-router-dom'
import { isNotEmpty, useForm } from '@mantine/form'
import { tellUsAtom, tellUsWeightAtom } from '../../../../../store/takeoff-tokyo/tell-us-atom'
import { useAtom } from 'jotai'
import { industryDataAtom } from '../../../../../store/takeoff-tokyo/industry-data-atom'

export function Industries() {
  const [tellUs, setTellUs] = useAtom(tellUsAtom)
  const [tellUsWeight, setTellUsWeight] = useAtom(tellUsWeightAtom)
  const history = useHistory()

  const [industryData] = useAtom(industryDataAtom)

  // selected
  const [industryGroups, setIndustryGroups] = useState<string[]>([]) // strings
  const [industriesMaps, setIndustryMaps] = useState<string[]>([]) // strings
  const [subIndustries, setSubIndustries] = useState<string[]>([]) // ids

  // re-evaluate dependent options
  const industryMapOptions = industryData.industryMapOptions.filter((x) => industryGroups.includes(x.group))
  const subIndustryOptions = industryData.subIndustryOptions.filter((x) => industriesMaps.includes(x.group))

  // re-evaluate selection
  useEffect(() => {
    const newSelectableValues = industryMapOptions.map((x) => x.value)
    setIndustryMaps((prev) => prev.filter((x) => newSelectableValues.includes(x)))
  }, [industryGroups])
  useEffect(() => {
    const newSelectableValues = subIndustryOptions.map((x) => x.value)
    setSubIndustries((prev) => prev.filter((x) => newSelectableValues.includes(x)))
  }, [industriesMaps])

  const form = useForm({
    initialValues: {
      weight: '3',
      industries: [],
    },
    validate: {
      industries: isNotEmpty('required'),
      weight: isNotEmpty('required'),
    },
  })

  useEffect(() => {
    const { industryGroupPayload, subIndustryGroupPayload } = industryData
    form.setFieldValue('industries', [...industryGroupPayload(industryGroups), ...subIndustryGroupPayload(subIndustries)])
  }, [industryGroups, subIndustries])

  useEffect(() => {
    // Load edited form
    // because they are persisted in local storage, we useEffect to load (loading from disk to memory takes time)
    form.setValues({
      weight: String(tellUsWeight.industries),
    })
    // load selected state from tellus
    if (industryGroups.length === 0 && industryData?.industryGroupOptions && tellUs?.industryGroups) {
      setIndustryGroups(tellUs.industryGroups)
      setIndustryMaps(tellUs.industriesMaps)
      setSubIndustries(tellUs.subIndustries)
    }
  }, [tellUs, tellUsWeight, industryData])

  const onSubmit = form.onSubmit((values) => {
    setTellUs((tellUs) => ({
      ...tellUs,
      industries: values.industries,
      // save selected states to tellUs
      industryGroups,
      industriesMaps,
      subIndustries,
    }))
    setTellUsWeight((tellUsWeight) => ({
      ...tellUsWeight,
      industries: values.weight,
    }))

    history.push('/takeoff-tokyo/investors/tell-us/countries')
  })

  return (
    <TokyoLayout progress={0}>
      <Title>Tell us about your investment focus</Title>
      <SubTitle>Industries</SubTitle>
      <Text>Select the industries and/or sub-industries in which you are targeting to invest in.</Text>
      <Text>投資対象として関心のある業界およびサブ業界を選択してください。</Text>
      <form onSubmit={onSubmit}>
        <Stack>
          <MultiSelect
            searchable
            data={industryData.industryGroupOptions}
            value={industryGroups}
            error={form.errors?.industries}
            onChange={setIndustryGroups}
            label="Industry groups"
            placeholder=""
            size="lg"
          />
          <MultiSelect searchable data={industryMapOptions} value={industriesMaps} onChange={setIndustryMaps} label="Industries" placeholder="" size="lg" />
          <MultiSelect searchable data={subIndustryOptions} label="Sub industries" placeholder="" size="lg" value={subIndustries} onChange={setSubIndustries} />
          <WeightInput {...form.getInputProps('weight')} />
          <Button type={'submit'}>Next</Button>
        </Stack>
      </form>
    </TokyoLayout>
  )
}
