import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import LeftSidebar from '../../components/LeftSidebar'
import { Redirect, Route, Switch } from 'react-router-dom'
import Account from '../Account'
import NotFound from '../NotFound'
import styles from './index.module.scss'
import { Companies } from '../Companies'
import { Lists } from '../Lists'
import * as Routes from '../../../logic/Routes'
import CompanyPage from '../Company'
import * as Backend from '../../../logic/Backend'
import { CompanyData, InvestmentProfileData, ListsPageData, ResponseForAgGrid, UserData } from '../../../logic/Backend'
import List from '../List'
import { ColumnApi, GridApi } from '@ag-grid-community/core'
import { showErrorToast } from '../../../logic/Toaster'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'
import Pipeline from '../Pipeline'
import { DashboardPage } from '../Dashboard'
import Chat from '../../pages/Chat'

export type Cache = {
  api?: GridApi
  columnApi?: ColumnApi
  companies?: ResponseForAgGrid<CompanyData>
  doubleClickedCompany?: CompanyData
  lists?: ResponseForAgGrid<ListsPageData>
  users?: UserData[]
  investmentProfiles?: InvestmentProfileData[]
  currentUser?: UserData
}
export type Set<T> = Dispatch<SetStateAction<T>>

const Standard: FC<WithNamespaces> = () => {
  const [cache, setCache] = useState<Cache>()
  const isChatPage = /(chat|dashboard|companies\/?)/.test(window.location.href)

  useEffect(() => {
    async function loadInvestmentProfiles() {
      if (cache && cache.api !== undefined && cache.investmentProfiles === undefined) {
        try {
          setCache({
            ...cache,
            investmentProfiles: await Backend.investmentProfiles(),
            currentUser: await Backend.currentUser(),
          } as Cache)
        } catch {
          showErrorToast(t('Failed to load investment profiles'))
        }
      }
    }

    loadInvestmentProfiles()
  }, [cache])

  return (
    <>
      <LeftSidebar cache={cache} />
      <div className={`${isChatPage && styles.greyBg} ${styles.page}`}>
        <Switch>
          <Route path={'/'} exact={true} render={() => <Redirect to={Routes.dashboard.href} />} />
          <Route path={Routes.lists.href} exact={true} render={(props) => <Lists cache={cache} setCache={setCache} {...props} />} />
          <Route path={Routes.list.href} exact={true} render={(props) => <List id={props.match.params.id!} cache={cache} setCache={setCache} {...props} />} />
          <Route path={Routes.companies.href} exact={true} render={(props) => <Companies cache={cache} setCache={setCache} {...props} />} />
          <Route path={Routes.company.href} exact={true} render={(props) => <CompanyPage id={props.match.params.id!} cache={cache} setCache={setCache} />} />
          <Route path={Routes.preferences.href} exact={true} component={Account} />
          <Route path="/pipeline" exact={true} render={() => <Pipeline />} />
          <Route path={Routes.dashboard.href} exact={true} component={DashboardPage} />
          <Route path={Routes.chat.href} exact={true} component={Chat} />
          <Route path={'/*'} exact={true} component={NotFound} />
        </Switch>
      </div>
    </>
  )
}

export default withNamespaces()(Standard)
