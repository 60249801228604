import React from 'react'
import { CompanyDetailsCard } from '../../../../../stories/CompanyDetailsCard'
import { CbLiOrPlaceholderImage } from '../../CbLiOrPlaceholderImage'
import { LinkedinEmployee } from '../../../../../stories/LinkedinEmployee'
import { internationalUnits, liType, linkFromUrlOrDomain } from '../../../../../logic/ValueFormatters'
import styles from './LinkedinCard.module.scss'
import { CompanyData } from '../../../../../logic/Backend'

interface LinkedinCardProps {
  company: CompanyData
  t: (key: string) => string
  localization: string
  linkedinUrl: string
}

export const LinkedinInfoCard: React.FC<LinkedinCardProps> = ({ company, t, localization, linkedinUrl }) => {
  return (
    <div className={styles.headerCard}>
      <CompanyDetailsCard
        thingsToDisplay={[
          localization === 'jp' ? company?.name_jp : company?.li_name,
          company?.li_logo_url,
          company?.li_type_enum,
          company?.li_followers_count_integer,
          company?.linkedin_url,
        ]}
        naLinkMessage={'Visit LinkedIn'}
        naLinkUrl={company?.linkedin_url}
        className={styles.linkedinCard}
      >
        {company?.li_name && (
          <div className={styles.linkedinCardContainer}>
            <div className={styles.linkedinHeaderContainer}>
              <CbLiOrPlaceholderImage li_logo_url={company.li_logo_url} altText={'Logo'} size={70} className={styles.linkedinHeaderLogo} />
              <div className={styles.linkedinInfoContainer}>
                <div className={styles.nameAndType}>
                  {company?.li_name && <span className={styles.linkedinName}>{company?.li_name}</span>}
                  {company?.li_type_enum != null && <span className={styles.companyType}>{liType(company?.li_type_enum)}</span>}
                </div>
                {company?.li_followers_count_integer != null && (
                  <span className={styles.followersCount}>
                    {internationalUnits(company?.li_followers_count_integer, 1, '', 'n/a')} {t('Followers')}
                  </span>
                )}
                {linkedinUrl && (
                  <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" className={styles.linkedinUrl}>
                    {linkedinUrl}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
      </CompanyDetailsCard>
    </div>
  )
}

export const LinkedinEmployeeCard: React.FC<LinkedinCardProps> = ({ company, t }) => {
  // Limit to maximum 4 employees
  const displayedEmployees = company?.li_employee_list?.slice(0, 4) || []
  return (
    <CompanyDetailsCard
      thingsToDisplay={[company?.li_employee_list, company?.li_employee_count_integer, company?.linkedin_url]}
      naLinkMessage={'View Employees on LinkedIn'}
      naLinkUrl={company?.linkedin_url}
      className={styles.linkedinCard}
    >
      {company?.li_employee_list && (
        <div className={styles.linkedinEmployeeContainer}>
          <div className={styles.employeeSectionHeader}></div>
          <div className={styles.linkedinEmployeeGrid}>
            {displayedEmployees.map((employee, i) => (
              <LinkedinEmployee
                key={i}
                profile_url={employee.profile_url}
                profile_picture_url={employee.profile_picture_url}
                role={employee.role}
                name={employee.name}
              />
            ))}
          </div>
          {company?.linkedin_url && (
            <a href={linkFromUrlOrDomain(company?.linkedin_url)} target="_blank" rel="noopener noreferrer" className={styles.viewMoreEmployees}>
              {t('View More Employees')}
            </a>
          )}
        </div>
      )}
    </CompanyDetailsCard>
  )
}

export const LinkedinCard: React.FC<LinkedinCardProps> = (props) => {
  const { company, t } = props
  const employeeCount = internationalUnits(company?.li_employee_count_integer, 1, '', 'n/a')
  return (
    <>
      <h2>{t('LinkedIn')}</h2>
      <LinkedinInfoCard {...props} />
      {/* <span className={styles.employeeNumber}>
        {employeeCount} {t('Employees')}
      </span> */}
      {/* <LinkedinEmployeeCard {...props} /> */}
    </>
  )
}
