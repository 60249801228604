import React, { ReactElement } from 'react'
import styles from './style.module.scss'
import NewsItem from './NewsItem'
import { INewsItem } from '../../../../logic/Backend'
import Skeleton from 'react-loading-skeleton'
import { t } from 'i18next'
import { useDispatch, useSelector } from 'react-redux'

type NewsResponse = {
  from: number
  to: number
  total_count: number
  news: INewsItem[]
}

interface Props {
  news: NewsResponse
  filters?: JSX.Element
}

const News = ({ news, filters }: Props) => {
  const localization = localStorage.getItem('language')
  const dispatch = useDispatch()
  const newsState = useSelector((state: any) => state.newsFilterState)

  const handleScroll = (e: React.UIEvent<HTMLDivElement>): void => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
    if (scrollHeight - scrollTop <= clientHeight + 1 && news.total_count + 1 > news.to && !newsState.paginationLoading) {
      dispatch({ type: 'newsFilterState/updatePaginationLoading', payload: true })
      setTimeout(() => {
        dispatch({ type: 'newsFilterState/updateFrom', payload: newsState.from + 50 })
      }, 1000) // Adding a 1000ms buffer to prevent double calls
    }
  }

  return (
    <div className={styles.newsContainer}>
      <h2 className={styles.heading}>{t('Latest News')}</h2>
      {filters}
      <div className={`${styles.newsGrid}`} onScroll={handleScroll}>
        {newsState.initialLoading
          ? ((<Skeleton count={5} height={80} />) as ReactElement)
          : news?.news.map((news, i) => {
              return (
                <NewsItem
                  key={i}
                  companyName={news.company_name}
                  articleDate={news.published_date}
                  articleTitle={localization === 'jp' ? news.title_jp : news.title_en}
                  articleSummary={localization === 'jp' ? news.summary_jp : news.summary_en}
                  // tags={news.tags}
                  srcImg={news.image_url}
                  companyId={news.company_id}
                  logo={news.company_logo_id}
                  url={news.url}
                />
              )
            })}
        {newsState.paginationLoading && ((<Skeleton count={1} width={'200%'} height={80} />) as ReactElement)}
      </div>
    </div>
  )
}

export default News
