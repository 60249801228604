import { Box, Button, Text, Title } from '@mantine/core'
import { IconArrowForward } from '../../../IconArrowForward'
import React from 'react'
import { TokyoLayout } from '../../../../components/takeoff-tokyo/TokyoLayout'

export function AllSet() {
  return (
    <TokyoLayout>
      <Title>You&apos;re all set</Title>
      <Text>We’ll contact you when your recommendations are ready.</Text>
      <div>
        <Button component={'a'} href={'/'}>
          Discover
          <Box fw={700} mx={'.7ex'}>
            Kurrant.ai
          </Box>
          <IconArrowForward />
        </Button>
      </div>
    </TokyoLayout>
  )
}
