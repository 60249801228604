import React from 'react'
import { Button, Stack, Text, TextInput, Title, Anchor } from '@mantine/core'
import { IconArrowForward } from '../../../components/takeoff-tokyo/IconArrowForward'
import { TokyoLayout } from '../../../components/takeoff-tokyo/TokyoLayout'
import { useHistory } from 'react-router-dom'

import { useForm } from '@mantine/form'

export function Welcome() {
  const history = useHistory()

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  return (
    <TokyoLayout>
      <Title>Welcome!</Title>
      <Text>
        Welcome to Takeoff Tokyo with the help of Kurrant.ai! We’re here to help you discover promising startups that align with your investment interests.
        Simply set your preferences, explore tailored matches, and seamlessly schedule meetings with founders ready to connect. Let’s make meaningful
        connections and unlock new opportunities!
      </Text>
      <Text>
        Takeoff Tokyo × Kurrant.aiへようこそ！ 投資の関心に合った有望なスタートアップ企業との出会いをサポートいたします。
        投資の希望条件を設定し、厳選されたマッチングリストをチェック。興味のある創業者とスムーズに商談を設定しましょう。
        新たなつながりを築き、可能性を広げる一歩を踏み出しましょう！
      </Text>
      <form
        onSubmit={form.onSubmit((values) => {
          const { email } = values
          history.push('/takeoff-tokyo/investors/create-account', {
            email,
          })
        })}
      >
        <Stack>
          <TextInput placeholder="james@some.vc" label={'Email'} {...form.getInputProps('email')} />
          <Button type="submit">
            Let&apos;s get started
            <IconArrowForward />
          </Button>
        </Stack>
      </form>
      <Text ta="right">
        Already signed up? <Anchor href="/takeoff-tokyo/investors/welcome-back">Click here</Anchor>
      </Text>
    </TokyoLayout>
  )
}
