import React, { FC, useEffect, useState } from 'react'
import SearchBar from './SearchBar'
import styles from './style.module.scss'
import News from './News'
import { RecommendedCompanies } from './RecommendedCompanies'
import NewlyDiscoveredCompanies from './NewCompanies'
import HotCompanies from './HotCompanies'
import TeamActivity from './TeamActivity'
import BrowsingHistory from './BrowsingHistory'
import { ActivityType, currentUser, getTeamActivity, UserData, INewsItem, getLatestNews } from '../../../logic/Backend'
import { useHistory } from 'react-router-dom'
import { showErrorToast } from '../../../logic/Toaster'
import useIsSmallScreen from '../../../hooks/useDetectScreenSize'
import FilterContainer from './News/FilterContainer'
import { useDispatch, useSelector } from 'react-redux'

type NewsResponse = {
  from: number
  to: number
  total_count: number
  news: INewsItem[]
}

export const DashboardPage: FC = () => {
  const [user, setUser] = useState<UserData>()
  const [teamActivityLoading, setTeamActivityLoading] = useState(false)
  const [teamActivity, setTeamActivity] = useState<ActivityType[]>()
  const isSmallScreen = useIsSmallScreen()
  const newsState = useSelector((state: any) => state.newsFilterState)
  const [news, setNews] = useState<NewsResponse>({ from: 0, to: 0, total_count: 0, news: [] })
  const [shouldClearFilter, setShouldClearFilter] = useState(false)

  useEffect(() => {
    const getCurrentUser = async () => {
      setUser(await currentUser())
    }
    getCurrentUser()

    const fetchActivity = async () => {
      setTeamActivityLoading(true)
      const response = await getTeamActivity({ from: 0, to: 10 })
      setTeamActivity(response.activities)
      setTeamActivityLoading(false)
    }
    fetchActivity()
  }, [])
  const dispatch = useDispatch()
  const apiUrl = `${process.env.REACT_APP_SEARCH_BAR_API}?message=`
  const history = useHistory()

  const sendMessage = async (message: string) => {
    history.push(`/chat`)
    dispatch({ type: 'chatMessagesState/updateChatHistory', payload: { message: message, sender: 'user' } })
    dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: true })
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message, user_id: user.id }),
      })
      const res = await response.json()
      dispatch({ type: 'chatMessagesState/updateChatHistory', payload: { message: res.answer, sender: 'ai', sessionId: res.session_id } })
      dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: false })
    } catch (e) {
      showErrorToast('Error sending message')
      dispatch({ type: 'chatLoadingState/updateChatLoadingState', payload: false })
    }

    return
  }
  const onSuggestedConversationClick = (text: string) => {
    sendMessage(text)
  }

  useEffect(() => {
    const getNews = async () => {
      try {
        newsState.from === 0 && dispatch({ type: 'newsFilterState/updateInitialLoading', payload: true })
        const res = await getLatestNews({ filters: newsState.filterState, from: newsState.from })
        newsState.from === 0
          ? setNews(res)
          : setNews((prevState) => {
              return { from: res.from, to: res.to, total_count: res.total_count, news: [...prevState.news, ...res.news] }
            })
      } catch (e) {
        console.error('Error fetching latest news:', e)
      } finally {
        dispatch({ type: 'newsFilterState/updateInitialLoading', payload: false })
        dispatch({ type: 'newsFilterState/updatePaginationLoading', payload: false })
      }
    }
    getNews()
  }, [newsState.filterState, newsState.from])

  const onFilterSelected = (key: string, value: string) => {
    dispatch({ type: 'newsFilterState/updateActiveFilters', payload: { key, value } })
    dispatch({ type: 'newsFilterState/updateFrom', payload: 0 })
  }
  const handleClear = () => {
    dispatch({ type: 'newsFilterState/clearAllFilters' })

    setShouldClearFilter(true)
    dispatch({ type: 'newsFilterState/updateFrom', payload: 0 })
  }

  const resetClearFilter = () => {
    setShouldClearFilter(false)
  }

  return (
    <div className={styles.dashboardContainer}>
      <>
        <BrowsingHistory />
        <SearchBar sendMessage={sendMessage} onSuggestedConversationClick={onSuggestedConversationClick} />
        <div className={styles.newsContainer}>
          <News
            news={news}
            filters={
              <FilterContainer
                onFilterSelected={onFilterSelected}
                handleClear={handleClear}
                shouldClearFilter={shouldClearFilter}
                resetClearFilter={resetClearFilter}
              />
            }
          />
        </div>
        <div className={styles.componentGrid}>
          <NewlyDiscoveredCompanies />
          <HotCompanies />
          <RecommendedCompanies />
          {isSmallScreen && <TeamActivity data={teamActivity} loading={teamActivityLoading} />}
        </div>
        {!isSmallScreen && (
          <div className={styles.teamActivity}>
            <TeamActivity data={teamActivity} loading={teamActivityLoading} />
          </div>
        )}
      </>
    </div>
  )
}
