import { Title, TitleProps } from '@mantine/core'
import React from 'react'

export function SubTitle(props: TitleProps) {
  return (
    <Title
      order={3}
      fw={700}
      sx={{
        fontSize: '24px',
        lineHeight: '30px',
      }}
      {...props}
    />
  )
}
