export const kurrantTheme = {
  globalStyles: (theme) => ({
    body: {
      color: '#484849',
    },
  }),
  components: {
    Stack: {
      defaultProps: { spacing: 24 },
    },
    Badge: {
      defaultProps: { size: 'lg' },
    },
    Chip: {
      defaultProps: { size: 'lg' },
    },
    Text: {
      defaultProps: { fs: '16px', lh: '22px' },
    },
    Title: {
      defaultProps: { fs: '40px', lh: '48px' },
    },
    Button: {
      defaultProps: { fw: '400', size: 'lg' },
      styles: (theme) => ({
        label: {
          fontSize: 16,
        },
      }),
    },
    PasswordInput: {
      defaultProps: { size: 'lg' },
      styles: (theme) => ({
        label: {
          fontSize: 13,
        },
      }),
    },
    TextInput: {
      defaultProps: { size: 'lg' },
      styles: (theme) => ({
        label: {
          fontSize: 13,
        },
      }),
    },
    NumberInput: {
      defaultProps: { size: 'lg', hideControls: false },
      styles: (theme) => ({
        label: {
          fontSize: 13,
        },
      }),
    },
    YearPickerInput: {
      defaultProps: { size: 'lg' },
      styles: (theme) => ({
        label: {
          fontSize: 13,
        },
      }),
    },
    Select: {
      defaultProps: { size: 'lg' },
      styles: (theme) => ({
        label: {
          fontSize: 13,
        },
      }),
    },
    MultiSelect: {
      defaultProps: { size: 'lg' },
      styles: (theme) => ({
        label: {
          fontSize: 13,
        },
      }),
    },
  },
  colors: {
    brand: [
      '#E3D9F7', // Shade 0
      '#E3D9F7', // Shade 0
      '#D6B8ED', // Shade 1
      '#C89EE3', // Shade 2
      '#BB85D9', // Shade 3
      '#A86CCF', // Shade 4
      '#9855E3', // Shade 5
      '#7D43B4', // Shade 6
      '#6B359E', // Shade 7
      '#58278A', // Shade 8
    ],
  },
  primaryColor: 'brand',
}
