import { TokyoLayout } from '../../../components/takeoff-tokyo/TokyoLayout'
import { Button, Group, Text } from '@mantine/core'
import { IconArrowForward } from '../../IconArrowForward'
import React, { useEffect } from 'react'
import { SubTitle } from '../../../components/takeoff-tokyo/SubTitle'
import { Redirect, useHistory } from 'react-router-dom'
import { useAtom } from 'jotai'
import { currentUserAtom } from '../../../../store/takeoff-tokyo/current-user-atom'

export function WelcomeBack() {
  const history = useHistory()
  const [currentUser, refreshCurrentUser] = useAtom(currentUserAtom)
  useEffect(() => {
    refreshCurrentUser()
  }, [])

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <TokyoLayout>
      <SubTitle>Welcome back!</SubTitle>
      <Text>
        Welcome back to Takeoff Tokyo X Kurrant.ai! We’re working on matching you with investors interested in your startup. We will email you meeting
        invitations with any matches you make with investors, and feel free to update your profile and availability anytime to maximize your opportunities.
        Exciting connections are on the way!
      </Text>
      <Text>
        Takeoff Tokyo × Kurrant.aiへようこそ！
        現在、貴社の投資希望にマッチするスタートアップ企業を積極的に検索しています。マッチング結果を随時ご確認ください。AIによるマッチングには数分かかる場合がありますので、すぐに結果が出なくてもご安心ください。少し後で再度ご確認いただければ、結果が表示されます。また、商談の機会を増やすために、いつでもスケジュールの更新できますのでお忘れなく！
        新たなつながりを楽しみにお待ちください！
      </Text>
      <Group spacing={8}>
        <Button onClick={() => history.push('/takeoff-tokyo/startups/edit-startup-profile')}>
          Review profile
          <IconArrowForward />
        </Button>
        <Button onClick={() => history.push('/takeoff-tokyo/startups/availability')}>
          Review availabilities
          <IconArrowForward />
        </Button>
      </Group>
    </TokyoLayout>
  )
}
