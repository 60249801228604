import { Center } from '@mantine/core'
import { HiOutlineChevronRight } from 'react-icons/hi2'
import React from 'react'

export function IconArrowForward() {
  return (
    <Center ml={'.6ex'}>
      <HiOutlineChevronRight size={16} strokeWidth={1.6} />
    </Center>
  )
}
