import React, { FC, Suspense, useEffect } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import LoginPage from '../pages/LoginPage'
import Standard from '../pages/Standard'
import * as Routes from '../../logic/Routes'
import { useLocation } from 'react-router'
import ahoy from 'ahoy.js'
import SignupPage from '../pages/SignupPage'
import PasswordResetPage from '../pages/PasswordResetPage'
import { createBrowserHistory } from 'history'
import { kurrantTheme } from '../config/mantine/kurrant-theme'
import { MantineProvider, Title } from '@mantine/core'
import { StartupsRoute } from '../pages/takeoff-tokyo/startups/StartupsRoute'
import { InvestorsRoute } from '../pages/takeoff-tokyo/investors/InvestorsRoute'
import { TokyoLayout, TokyoLayoutLoader } from '../components/takeoff-tokyo/TokyoLayout'

export interface HistoryState {
  from: Location['pathname']
}

export const routerHistory = createBrowserHistory<HistoryState>()

const App: FC = () => {
  return (
    <Router history={routerHistory}>
      <Page />
    </Router>
  )
}

const Page: FC = () => {
  const location = useLocation()
  useEffect(() => {
    ahoy.trackView()
  }, [location.pathname])

  return (
    <Switch>
      <Route path={Routes.login.href} exact={true} component={LoginPage} />
      <Route path={Routes.signup.href} exact={true} component={SignupPage} />
      <Route path={Routes.passwordReset.href} exact={true} component={PasswordResetPage} />
      <Route
        path={'*'}
        render={({ location }) =>
          location.pathname.startsWith('/takeoff-tokyo') ? (
            <MantineProvider withGlobalStyles withNormalizeCSS theme={kurrantTheme}>
              <Suspense fallback={<TokyoLayoutLoader />}>
                <Switch>
                  <Route path={'/takeoff-tokyo/investors'}>
                    <InvestorsRoute />
                  </Route>
                  <Route path={'/takeoff-tokyo/startups'}>
                    <StartupsRoute />
                  </Route>
                  <Route>
                    <TokyoLayout>
                      <Title>Page not found.</Title>
                    </TokyoLayout>
                  </Route>
                </Switch>
              </Suspense>
            </MantineProvider>
          ) : (
            <Standard />
          )
        }
      />
    </Switch>
  )
}

export default App
