import { ActionIcon, ActionIconProps } from '@mantine/core'
import { FaArrowLeft } from 'react-icons/fa6'
import React from 'react'

export function BackButton({ onClick, ...props }: ActionIconProps & { onClick?: () => void }) {
  return (
    <ActionIcon
      variant="light"
      color="gray"
      size="xl"
      radius="xl"
      aria-label="Back"
      style={{
        transform: 'translateX(-30%)',
      }}
      component={'button'}
      onClick={onClick}
      {...props}
    >
      <FaArrowLeft color="#484849" style={{ width: '50%', height: '50%' }} />
    </ActionIcon>
  )
}
