import React, { useState } from 'react'
import { KeyContact } from '../../../../../stories/KeyContact'
import { CompanyData } from '../../../../../logic/Backend'
import styles from './KeyContactsCard.module.scss'
import noDataImage from '../../../../../resources/images/no-data.png'

interface KeyContactsCardProps {
  company: CompanyData | undefined
}

export const KeyContactsCard: React.FC<KeyContactsCardProps> = ({ company }) => {
  const [expanded, setExpanded] = useState(false)
  const displayMorePeople = company?.people?.length <= 4
  return (
    <div
      className={
        displayMorePeople && !company?.li_employee_count_latest_6_months?.some((value) => value !== null)
          ? styles.employeeContainerSingle
          : styles.employeesContainer
      }
    >
      {company?.people?.length > 0 ? (
        <>
          <div className={styles.employeeGrid}>
            {company?.people?.map((employee, i) => {
              if (!expanded && i >= 4) return
              return <KeyContact key={i} {...employee} />
            })}
          </div>
          {displayMorePeople ? (
            <></>
          ) : expanded ? (
            <button className={styles.button} onClick={() => setExpanded(false)}>
              View Fewer Employees
            </button>
          ) : (
            <button className={styles.button} onClick={() => setExpanded(true)}>
              View More Employees
            </button>
          )}
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center', margin: '0 auto', padding: '20px' }}>
          <img src={noDataImage} alt="No data" width={100} height={87} />
          <span>
            <strong>Data</strong> is unavailable at this time.
          </span>
        </div>
      )}
    </div>
  )
}
