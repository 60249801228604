import { Button, Flex, Input, NumberInput, Stack, Text } from '@mantine/core'
import { SubTitle } from '../../../../components/takeoff-tokyo/SubTitle'
import { TbCurrencyDollar } from 'react-icons/tb'
import React, { useEffect } from 'react'
import { WeightInput } from '../../../../components/takeoff-tokyo/WeightInput'
import { useHistory } from 'react-router-dom'
import { TokyoLayout } from '../../../../components/takeoff-tokyo/TokyoLayout'
import { useAtom } from 'jotai'
import { NumberInputValue, tellUsAtom, tellUsWeightAtom } from '../../../../../store/takeoff-tokyo/tell-us-atom'
import { isNotEmpty, useForm } from '@mantine/form'

export function TotalFundingAmount() {
  const [tellUs, setTellUs] = useAtom(tellUsAtom)
  const [tellUsWeight, setTellUsWeight] = useAtom(tellUsWeightAtom)
  const history = useHistory()

  const form = useForm<{
    from: NumberInputValue
    to: NumberInputValue
    weight: string
  }>({
    initialValues: {
      from: '',
      to: '',
      weight: '',
    },
    // validate: {
    //   from: isNotEmpty('required'),
    //   to: isNotEmpty('required'),
    // },
  })

  useEffect(() => {
    form.setValues({
      from: tellUs.totalFundingAmount[0] ?? '',
      to: tellUs.totalFundingAmount[1] ?? '',
      weight: String(tellUsWeight.totalFundingAmount),
    })
  }, [tellUs, tellUsWeight])

  const onSubmit = form.onSubmit((values) => {
    setTellUs((tellUs) => ({
      ...tellUs,
      totalFundingAmount: [values.from, values.to],
    }))
    setTellUsWeight((tellUsWeight) => ({
      ...tellUsWeight,
      totalFundingAmount: values.weight,
    }))
    history.push('/takeoff-tokyo/investors/tell-us/employee-count')
  })

  return (
    <TokyoLayout progress={60}>
      <SubTitle>Total Funding Amount</SubTitle>
      <Text>Select the prior total funding amount of your target companies.</Text>
      <Text>投資対象企業の過去の資金調達額を選択してください。</Text>
      <form onSubmit={onSubmit}>
        <Stack>
          <Input.Wrapper label={'Total funding between'}>
            <Flex align={'center'} justify={'space-between'}>
              <NumberInput placeholder="1000/1K" icon={<TbCurrencyDollar />} hideControls {...form.getInputProps('from')} />
              <Text px={'1.5ex'}>and</Text>
              <NumberInput placeholder="10000/10K" icon={<TbCurrencyDollar />} hideControls {...form.getInputProps('to')} />
            </Flex>
          </Input.Wrapper>
          <WeightInput {...form.getInputProps('weight')} />
          <Flex justify={'space-between'}>
            <Button variant={'outline'} onClick={() => history.push('/takeoff-tokyo/investors/tell-us/funding-stage')}>
              Back
            </Button>
            <Button type={'submit'}>Next</Button>
          </Flex>
        </Stack>
      </form>
    </TokyoLayout>
  )
}
