import { BackgroundImage, Box, Center, Flex, Image, Loader, Paper, Progress, ProgressProps, ScrollArea, Stack } from '@mantine/core'
import takeoffBackground from '../../../resources/images/takeoff-bg.png'
import takeoffForeground from '../../../resources/images/takeoff-fg.png'
import React, { ReactNode } from 'react'
import { LogoKurrantAi } from './LogoKurrantAi'
import { PoweredBy } from './PoweredBy'

export function TokyoLayout({ children, debug, progress }: { children: ReactNode; debug?: ReactNode; progress?: number }) {
  return (
    <Flex
      bg={{ xs: '#F6F8FA' }}
      w={'100%'}
      sx={{
        position: 'relative',
      }}
    >
      <BackgroundImage
        sx={{
          display: 'none',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        maw={'48%'}
        display={{ sm: 'flex' }}
        src={takeoffBackground}
      >
        <Image maw={464} src={takeoffForeground} />
      </BackgroundImage>
      <Box
        sx={{
          flexGrow: 1,
          height: '100%',
        }}
      >
        <ScrollArea h={'100%'} w={'100%'}>
          <Stack
            align={'center'}
            justify={'space-between'}
            sx={(theme) => {
              const xs = `@media (min-width: ${theme.breakpoints.xs})`
              return {
                padding: '16px 24px 24px 24px',
                [xs]: {
                  padding: '80px 62px 40px 50px',
                },
              }
            }}
          >
            <Stack align={'center'}>
              <Center
                sx={(theme) => {
                  const xs = `@media (min-width: ${theme.breakpoints.xs})`
                  return {
                    display: 'none',
                    [xs]: {
                      display: 'flex',
                    },
                  }
                }}
              >
                <LogoKurrantAi />
              </Center>
              <Box>
                <Box
                  mt={16}
                  mb={20}
                  sx={(theme) => {
                    const xs = `@media (min-width: ${theme.breakpoints.xs})`
                    return {
                      display: 'block',
                      [xs]: {
                        display: 'none',
                      },
                    }
                  }}
                >
                  {progress ? <ProgressBar value={progress} /> : null}
                </Box>
                <Paper
                  sx={(theme) => {
                    const xs = `@media (min-width: ${theme.breakpoints.xs})`
                    return {
                      padding: '0px',
                      border: 'none',
                      [xs]: {
                        padding: '56px',
                        border: '1px solid #48484933',
                        minWidth: '450px',
                        maxWidth: '700px',
                      },
                    }
                  }}
                >
                  <Stack spacing={24}>{children}</Stack>
                </Paper>
                <Box
                  sx={(theme) => {
                    const xs = `@media (min-width: ${theme.breakpoints.xs})`
                    return {
                      display: 'none',
                      [xs]: {
                        display: 'block',
                        transform: 'translateY(-100%)',
                      },
                    }
                  }}
                >
                  {progress ? <ProgressBar value={progress} /> : null}
                </Box>
              </Box>
            </Stack>
            <PoweredBy />
          </Stack>
          {debug ? <Box>{debug}</Box> : null}
        </ScrollArea>
      </Box>
    </Flex>
  )
}

function ProgressBar(props: ProgressProps) {
  return <Progress radius={0} w={'100%'} size={6} {...props} />
}

export function TokyoLayoutLoader() {
  return (
    <TokyoLayout>
      <Center mih={'60vh'}>
        <Loader size="lg" color={'brand'} />
      </Center>
    </TokyoLayout>
  )
}
