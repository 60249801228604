import { Anchor, Checkbox, CheckboxProps, Text } from '@mantine/core'
import React from 'react'

export function CheckboxAgreeTerms(props: CheckboxProps) {
  return (
    <Checkbox
      defaultChecked={false}
      label={
        <Text size="md">
          By signing up, I agree to Kurrant.ai&apos;s
          <Anchor c={'brand'} px={'.5ex'} fw={600} href={'https://www.kurrant.ai/terms-of-use'} target="_blank">
            Terms of Use
          </Anchor>
          and
          <Anchor c={'brand'} px={'.5ex'} fw={600} href={'https://www.kurrant.ai/privacy-policy'} target="_blank">
            Privacy Policy
          </Anchor>
        </Text>
      }
      {...props}
    />
  )
}
