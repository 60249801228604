import React, { useState } from 'react'
import styles from './style.module.scss'

interface Props {
  sections: string[]
  onSectionClick: (section: string) => void
  selected: string
}

const NavbarButtons = (props: Props) => {
  const { selected, sections, onSectionClick } = props
  return (
    <>
      {sections.map((section) => {
        return (
          <div className={selected == section ? styles.buttonSelected : styles.button} key={section} onClick={() => onSectionClick(section)}>
            {section}
          </div>
        )
      })}
    </>
  )
}

export default NavbarButtons
