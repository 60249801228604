import React, { CSSProperties, FC, ReactNode } from 'react'
import { ButtonProps } from '@blueprintjs/core'
import noDataImage from '../resources/images/no-data.png'

export interface CompanyDetailsCardProps extends ButtonProps {
  children: ReactNode
  title?: string
  thingsToDisplay?: unknown[]
  naTitle?: string
  naLinkMessage?: string
  naLinkUrl?: string
  flat?: boolean
  style?: CSSProperties
  className?: string
}

export const CompanyDetailsCard: FC<CompanyDetailsCardProps> = ({
  title,
  children,
  thingsToDisplay,
  naTitle,
  naLinkMessage,
  naLinkUrl,
  flat,
  style,
  className,
}) => {
  const somethingToDisplay = thingsToDisplay?.some((t) => t != null) ?? true
  return (
    <div>
      {title && <h2>{title}</h2>}
      <div
        className={className}
        style={{
          // display: 'flex',
          // flexDirection: somethingToDisplay ? 'column' : 'row',
          // gap: '16px',
          ...(!somethingToDisplay && { alignItems: 'center' }),
          ...style,
        }}
      >
        {somethingToDisplay ? (
          <>{children}</>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'center', margin: '0 auto' }}>
            <img src={noDataImage} alt="No data" width={100} height={87} />
            <span>
              <strong>{title ?? naTitle ?? 'Data'}</strong> is unavailable at this time.
            </span>
            {naLinkMessage && naLinkUrl && (
              <a href={naLinkUrl} target="_blank" rel="noopener noreferrer">
                {naLinkMessage}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
