import { Avatar, Button, Flex, Grid, Group, Loader, RingProgress, Space, Stack, Text, Title } from '@mantine/core'
import { IconArrowForward } from '../../IconArrowForward'
import React, { useEffect } from 'react'
import { TokyoLayout } from '../../../components/takeoff-tokyo/TokyoLayout'
import { useAtom } from 'jotai'
import { startupRecommendationAtom } from '../../../../store/takeoff-tokyo/startup-recommendation-atom'
import { Redirect, useHistory } from 'react-router-dom'
import { currentUserAtom } from '../../../../store/takeoff-tokyo/current-user-atom'

export function WelcomeBack() {
  const [currentUser, refreshCurrentUser] = useAtom(currentUserAtom)
  const [startups, refreshStartups] = useAtom(startupRecommendationAtom)
  useEffect(() => refreshCurrentUser(), [])
  useEffect(() => refreshStartups(), [])
  const hasRecommendations = startups?.length > 0

  const history = useHistory()

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <TokyoLayout>
      <Title>Welcome back!</Title>
      {hasRecommendations ? (
        <>
          <Text>
            Welcome back to Takeoff Tokyo X Kurrant.ai! Your tailored startup matches are waiting—explore new opportunities, refine your preferences, and
            schedule meetings with promising founders. Let’s continue building connections that drive impactful investments!
          </Text>
          <Text>
            Takeoff Tokyo X Kurrant.ai へおかえりなさい！ あなたに最適なスタートアップとのマッチが準備されています。
            新たな機会を探索し、投資の好みに合わせて調整し、有望な創業者とのミーティングをスケジュールしましょう。
            価値あるつながりを築き、インパクトのある投資を実現していきましょう！
          </Text>
        </>
      ) : (
        <>
          <Text>
            Welcome back to Takeoff Tokyo X Kurrant.ai! We’re actively searching for startups that match your investment preferences. Keep checking back to
            review your matches, our AI takes a few minutes to calculate your matches so do not worry if you do not have matches right away, please come back
            shortly for your results, and don’t forget—you can update your availability anytime to increase your chances of scheduling the perfect meetings.
            Stay tuned for your connections!
          </Text>
          <Text>
            Takeoff Tokyo X Kurrant.ai へおかえりなさい！ あなたの投資の好みに合うスタートアップを積極的に検索中です。
            マッチングの計算には数分かかるため、すぐに結果が表示されなくてもご安心ください。 少し時間をおいてから再度チェックしてみてください。
            また、より良いミーティングの機会を得るために、いつでも利用可能な時間を更新できます。 新たなつながりを楽しみにお待ちください！
          </Text>
        </>
      )}
      <Group spacing={8}>
        <Button disabled={!hasRecommendations} onClick={() => history.push('/takeoff-tokyo/investors/review-recommendation')}>
          {hasRecommendations ? (
            <>Review startups</>
          ) : (
            <Group spacing={8}>
              <>Preparing matches</> <Loader size="sm" color={'gray'} />
            </Group>
          )}
          {hasRecommendations && <IconArrowForward />}
        </Button>
        <Button onClick={() => history.push('/takeoff-tokyo/investors/availability')}>
          Review availabilities
          <IconArrowForward />
        </Button>
      </Group>
      {hasRecommendations && <Title size={24}>Your recommended startups at Takeoff Tokyo:</Title>}

      <Stack spacing={32}>
        {hasRecommendations &&
          startups.map((startup) => {
            const { id, organization } = startup
            const recommendation_score = Number(startup.recommendation_score)
            const tags = organization.industry_groups ? [organization.industry_groups] : []
            return (
              <RecommendationItem
                key={id}
                title={organization?.name ?? ''}
                description={organization?.description ?? ''}
                tags={tags}
                logoUrl={organization?.logo_upload?.url}
                score={recommendation_score}
              />
            )
          })}
      </Stack>
    </TokyoLayout>
  )
}

function RecommendationItem({
  title = 'RecommendationItem',
  description = '',
  logoUrl = '',
  tags = [],
  score = 69,
}: {
  title?: string
  tags?: string[]
  description?: string
  score?: number
  logoUrl?: string
}) {
  return (
    <Grid align={'start'}>
      <Grid.Col span="content">
        <Avatar src={logoUrl} size={35} radius={'100%'} />
        <Space w={16} />
      </Grid.Col>
      <Grid.Col span="auto">
        <Stack spacing={8}>
          <Text size={14} fw={600} color={'black'}>
            {title}
          </Text>
          <Group spacing="sm">
            {tags.map((tag) => (
              <BadgeRecommendation key={tag} text={tag} />
            ))}
          </Group>
          <Text size={14}>{description}</Text>
        </Stack>
      </Grid.Col>
      <Grid.Col span="content">
        <Space w={8} />
        <RingProgress
          label={
            <Text color="black" weight={500} align="center" size={10}>
              {Math.round(score)}
            </Text>
          }
          size={26}
          thickness={2}
          roundCaps
          sections={[{ value: score, color: '#5453DF' }]}
        />
      </Grid.Col>
    </Grid>
  )
}

function BadgeRecommendation({ variant, text }: { text: string; variant?: 'hot topic' | 'fast growing' | 'trending' }) {
  return (
    <Flex
      align={'center'}
      gap={4}
      px={8}
      h={20}
      sx={{
        border: '1px solid #48484933',
        borderRadius: '16px',
      }}
    >
      {variant === 'trending' ? (
        <>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_3908_366" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
              <rect width="12" height="12" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3908_366)">
              <path
                d="M3.81265 3.2L5.21265 1.3875C5.31265 1.25417 5.4314 1.15625 5.5689 1.09375C5.7064 1.03125 5.85015 1 6.00015 1C6.15015 1 6.2939 1.03125 6.4314 1.09375C6.5689 1.15625 6.68765 1.25417 6.78765 1.3875L8.18765 3.2L10.3127 3.9125C10.5293 3.97917 10.7002 4.10208 10.8252 4.28125C10.9502 4.46042 11.0127 4.65833 11.0127 4.875C11.0127 4.975 10.9981 5.075 10.9689 5.175C10.9397 5.275 10.8918 5.37083 10.8252 5.4625L9.45015 7.4125L9.50015 9.4625C9.50848 9.75417 9.41265 10 9.21265 10.2C9.01265 10.4 8.77932 10.5 8.51265 10.5C8.49599 10.5 8.40432 10.4875 8.23765 10.4625L6.00015 9.8375L3.76265 10.4625C3.72099 10.4792 3.67515 10.4896 3.62515 10.4937C3.57515 10.4979 3.52932 10.5 3.48765 10.5C3.22099 10.5 2.98765 10.4 2.78765 10.2C2.58765 10 2.49182 9.75417 2.50015 9.4625L2.55015 7.4L1.18765 5.4625C1.12099 5.37083 1.07307 5.275 1.0439 5.175C1.01474 5.075 1.00015 4.975 1.00015 4.875C1.00015 4.66667 1.06057 4.47292 1.1814 4.29375C1.30224 4.11458 1.47099 3.9875 1.68765 3.9125L3.81265 3.2Z"
                fill="#FCA203"
              />
            </g>
          </svg>
          <Text size={10} fw={500}>
            Trending
          </Text>
        </>
      ) : null}

      {variant === 'fast growing' ? (
        <>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_3908_361" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
              <rect width="12" height="12" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3908_361)">
              <path
                d="M3.7 8L5.225 6.475L6.225 7.475L8 5.7125V6.5H9V4H6.5V5H7.2875L6.225 6.0625L5.225 5.0625L3 7.3L3.7 8ZM2.5 10.5C2.225 10.5 1.98958 10.4021 1.79375 10.2063C1.59792 10.0104 1.5 9.775 1.5 9.5V2.5C1.5 2.225 1.59792 1.98958 1.79375 1.79375C1.98958 1.59792 2.225 1.5 2.5 1.5H9.5C9.775 1.5 10.0104 1.59792 10.2063 1.79375C10.4021 1.98958 10.5 2.225 10.5 2.5V9.5C10.5 9.775 10.4021 10.0104 10.2063 10.2063C10.0104 10.4021 9.775 10.5 9.5 10.5H2.5Z"
                fill="#4D52DE"
              />
            </g>
          </svg>
          <Text size={10} fw={500}>
            Fast growing
          </Text>
        </>
      ) : null}

      {variant === 'hot topic' ? (
        <>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_3908_1373" maskUnits="userSpaceOnUse" x="0" y="0" width="12" height="12">
              <rect width="12" height="12" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3908_1373)">
              <path
                d="M1.99985 7C1.99985 6.125 2.20818 5.34583 2.62485 4.6625C3.04151 3.97917 3.49985 3.40417 3.99985 2.9375C4.49985 2.47083 4.95818 2.11458 5.37485 1.86875L5.99985 1.5V3.15C5.99985 3.45833 6.10401 3.70208 6.31235 3.88125C6.52068 4.06042 6.75401 4.15 7.01235 4.15C7.15401 4.15 7.28943 4.12083 7.4186 4.0625C7.54776 4.00417 7.66651 3.90833 7.77485 3.775L7.99985 3.5C8.59985 3.85 9.08318 4.33542 9.44985 4.95625C9.81651 5.57708 9.99985 6.25833 9.99985 7C9.99985 7.73333 9.82068 8.40208 9.46235 9.00625C9.10401 9.61042 8.63318 10.0875 8.04985 10.4375C8.19151 10.2375 8.30193 10.0188 8.3811 9.78125C8.46026 9.54375 8.49985 9.29167 8.49985 9.025C8.49985 8.69167 8.43735 8.37708 8.31235 8.08125C8.18735 7.78542 8.00818 7.52083 7.77485 7.2875L5.99985 5.55L4.23735 7.2875C3.99568 7.52917 3.81235 7.79583 3.68735 8.0875C3.56235 8.37917 3.49985 8.69167 3.49985 9.025C3.49985 9.29167 3.53943 9.54375 3.6186 9.78125C3.69776 10.0188 3.80818 10.2375 3.94985 10.4375C3.36651 10.0875 2.89568 9.61042 2.53735 9.00625C2.17901 8.40208 1.99985 7.73333 1.99985 7ZM5.99985 6.95L7.06235 7.9875C7.20401 8.12917 7.31235 8.2875 7.38735 8.4625C7.46235 8.6375 7.49985 8.825 7.49985 9.025C7.49985 9.43333 7.35401 9.78125 7.06235 10.0688C6.77068 10.3563 6.41651 10.5 5.99985 10.5C5.58318 10.5 5.22901 10.3563 4.93735 10.0688C4.64568 9.78125 4.49985 9.43333 4.49985 9.025C4.49985 8.83333 4.53735 8.64792 4.61235 8.46875C4.68735 8.28958 4.79568 8.12917 4.93735 7.9875L5.99985 6.95Z"
                fill="#E05175"
              />
            </g>
          </svg>
          <Text size={10} fw={500}>
            Hot topic
          </Text>
        </>
      ) : (
        <Text size={10} fw={500}>
          {text}
        </Text>
      )}
    </Flex>
  )
}
