import { Button, Flex, Stack, Text } from '@mantine/core'
import { SubTitle } from '../../../../components/takeoff-tokyo/SubTitle'
import React, { useEffect, useState } from 'react'
import { WeightInput } from '../../../../components/takeoff-tokyo/WeightInput'
import { useHistory } from 'react-router-dom'
import { TokyoLayout } from '../../../../components/takeoff-tokyo/TokyoLayout'
import { YearPickerInput } from '@mantine/dates'
import { useAtom } from 'jotai'
import { tellUsAtom, tellUsInit, tellUsWeightAtom, tellUsWeightInit, YearRange } from '../../../../../store/takeoff-tokyo/tell-us-atom'
import { useForm } from '@mantine/form'
import { newInvestmentProfile, NewInvestmentProfileRequest } from '../../../../../logic/Backend'
import dayjs from 'dayjs'
import { Simulate } from 'react-dom/test-utils'
import load = Simulate.load
import { showErrorToast, showSuccessToast } from '../../../../../logic/Toaster'

export function YearFounded() {
  const [tellUs, setTellUs] = useAtom(tellUsAtom)
  const [tellUsWeight, setTellUsWeight] = useAtom(tellUsWeightAtom)
  const history = useHistory()

  const form = useForm<{
    yearFounded: YearRange
    weight: string
  }>({
    initialValues: {
      yearFounded: [null, null],
      weight: '',
    },
  })

  useEffect(() => {
    form.setValues({
      weight: tellUsWeight.yearFounded,
    })
    if (tellUs.yearFounded[0] !== null && tellUs.yearFounded[1] !== null) {
      form.setValues({
        yearFounded: [dayjs(tellUs.yearFounded[0]).toDate(), dayjs(tellUs.yearFounded[1]).toDate()],
      })
    }
  }, [tellUs, tellUsWeight])

  const [loadingButton, setLoadingButton] = useState(false)

  const onSubmit = form.onSubmit(async (values) => {
    setTellUs((tellUs) => ({
      ...tellUs,
      yearFounded: values.yearFounded,
    }))
    setTellUsWeight((tellUsWeight) => ({
      ...tellUsWeight,
      yearFounded: values.weight,
    }))

    const yearNumbers = values.yearFounded.map((str) => dayjs(str).year())
    const investmentProfileRequest: NewInvestmentProfileRequest = {
      name: 'to edit', //

      industries: tellUs.industries,
      industries_weight: Number(tellUsWeight.industries),

      countries: tellUs.countries,
      countries_weight: Number(tellUsWeight.countries),

      funding_types: tellUs.fundingStage,
      funding_types_weight: Number(tellUsWeight.fundingStage),

      total_funding_usd_int8range: tellUs.totalFundingAmount.join('...'),
      total_funding_usd_int8range_weight: Number(tellUsWeight.totalFundingAmount),

      employee_count_int4range: tellUs.employeeCount.join('...'),
      employee_count_int4range_weight: Number(tellUsWeight.employeeCount),

      founded_on_int4range: yearNumbers.join('...'),
      founded_on_int4range_weight: Number(values.weight),
    }

    try {
      setLoadingButton(true)
      await newInvestmentProfile(investmentProfileRequest).then((res) => {
        // on success, clear storage
        setLoadingButton(false)
        setTellUs(tellUsInit)
        setTellUsWeight(tellUsWeightInit)
        showSuccessToast('Investor profile saved')
        history.push('/takeoff-tokyo/investors/availability')
      })
    } catch (e) {
      setLoadingButton(false)
      showErrorToast(`something went wrong, ${e.message}`)
      // TODO user feedback
    }
  })

  return (
    <TokyoLayout progress={100}>
      <SubTitle>Year Founded</SubTitle>
      <Text>Select the year founded range for your target companies.</Text>
      <Text>投資対象企業の設立年を選択してください。</Text>
      <form onSubmit={onSubmit}>
        <Stack>
          <YearPickerInput type="range" label="Founded in between" placeholder="Pick a range" {...form.getInputProps('yearFounded')} />
          <WeightInput {...form.getInputProps('weight')} />
          <Flex justify={'space-between'}>
            <Button variant={'outline'} onClick={() => history.push('/takeoff-tokyo/investors/tell-us/employee-count')}>
              Back
            </Button>
            <Button loading={loadingButton} type={'submit'}>
              Save
            </Button>
          </Flex>
        </Stack>
      </form>
    </TokyoLayout>
  )
}
