import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { Welcome } from './welcome'
import { CreateAccount } from './create-account'
import { Industries } from './tell-us/industries'
import { Countries } from './tell-us/countries'
import { FundingStage } from './tell-us/funding-stage'
import { TotalFundingAmount } from './tell-us/total-funding-amount'
import { EmployeeCount } from './tell-us/employee-count'
import { YearFounded } from './tell-us/year-founded'
import { AllSet } from './tell-us/all-set'
import { WelcomeBack } from './welcome-back'
import { ReviewRecommendation } from './review-recommendation'
import { Availability } from './availability'
import { useAtom } from 'jotai'
import { currentUserAtom } from '../../../../store/takeoff-tokyo/current-user-atom'

export function InvestorsRoute() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/welcome`}>
        <Welcome />
      </Route>
      <Route exact path={`${path}/create-account`}>
        <CreateAccount />
      </Route>

      <InvestorsUserRoute>
        <Route exact path={`${path}/tell-us/industries`}>
          <Industries />
        </Route>
        <Route exact path={`${path}/tell-us/countries`}>
          <Countries />
        </Route>
        <Route exact path={`${path}/tell-us/funding-stage`}>
          <FundingStage />
        </Route>
        <Route exact path={`${path}/tell-us/total-funding-amount`}>
          <TotalFundingAmount />
        </Route>
        <Route exact path={`${path}/tell-us/employee-count`}>
          <EmployeeCount />
        </Route>
        <Route exact path={`${path}/tell-us/year-founded`}>
          <YearFounded />
        </Route>
        <Route exact path={`${path}/tell-us/all-set`}>
          <AllSet />
        </Route>
        <Route exact path={`${path}/welcome-back`}>
          <WelcomeBack />
        </Route>
        <Route exact path={`${path}/review-recommendation`}>
          <ReviewRecommendation />
        </Route>
        <Route exact path={`${path}/availability`}>
          <Availability />
        </Route>
      </InvestorsUserRoute>
    </Switch>
  )
}

function InvestorsUserRoute({ children, ...rest }) {
  const [currentUser] = useAtom(currentUserAtom)
  const { created_from, startup_founder } = currentUser

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!currentUser)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )

        if (created_from === 'kurrant_meets' && startup_founder) {
          return (
            <Redirect
              to={{
                pathname: '/takeoff-tokyo/startups/welcome-back',
                state: { from: location },
              }}
            />
          )
        }
        if (created_from === 'kurrant_meets' && !startup_founder) return <>{children}</>
        return <Redirect to={'/'} />
      }}
    />
  )
}
