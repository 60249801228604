import React, { CSSProperties, FC } from 'react'
import { CompanyData, EmployeeData, LinkedinEmployeeData } from '../../../logic/Backend'
import placeholderLogo from '../../../resources/images/placeholder_avatar.svg'
import { Img } from 'react-image'

interface CbLiOrPlaceholderImageProps {
  logo_id?: CompanyData['logo_id']
  cb_profile_pic_url?: EmployeeData['cb_profile_pic_url']
  li_logo_url?: CompanyData['li_logo_url']
  li_profile_pic_url?: EmployeeData['li_profile_pic_url']
  profile_picture_url?: LinkedinEmployeeData['profile_picture_url']
  altText: string
  size?: number
  style?: CSSProperties
  className?: string
}

export const CbLiOrPlaceholderImage: FC<CbLiOrPlaceholderImageProps> = ({
  logo_id,
  cb_profile_pic_url,
  li_logo_url,
  li_profile_pic_url,
  profile_picture_url,
  size,
  style,
  altText,
  className,
}) => {
  function safeCbImage(image: string | undefined): boolean {
    return safeImage(image, 'placeholder')
  }

  function safeLiImage(image: string | undefined): boolean {
    return safeImage(image, 'aajlclc14rr2scznz5qm2rj9u')
  }

  function safeImage(image: string | undefined, placeHolderPattern: string): boolean {
    return image != undefined && !image.includes(placeHolderPattern)
  }

  const logoUrls = []
  if (safeCbImage(logo_id)) {
    // cb logo is either full/variable size, or 100x100 if we add params in the url
    logoUrls.push(
      'https://images.crunchbase.com/image/upload/' + (size != undefined && size <= 100 ? 'c_lpad,h_100,w_100,f_auto,b_white,q_auto:eco,dpr_1/' : '') + logo_id
    )
  }
  if (safeCbImage(cb_profile_pic_url)) {
    logoUrls.push(cb_profile_pic_url!)
  }
  if (safeLiImage(li_logo_url)) {
    // li logo is 200x200
    logoUrls.push(li_logo_url!)
  }
  if (safeLiImage(li_profile_pic_url)) {
    // li logo is 200x200
    logoUrls.push(li_profile_pic_url!)
  }
  if (safeLiImage(profile_picture_url)) {
    // li logo is 200x200
    logoUrls.push(profile_picture_url!)
  }
  logoUrls.push(placeholderLogo)
  return <Img src={logoUrls} className={className} style={{ objectFit: 'cover', ...style }} alt={altText} height={size} width={size} />
}
