import { Center } from '@mantine/core'
import { BsFillQuestionCircleFill } from 'react-icons/bs'
import React from 'react'

export function IconQuestionFill() {
  return (
    <Center mx={'.6ex'}>
      <BsFillQuestionCircleFill size={20} />
    </Center>
  )
}
