import React, { CSSProperties, FC } from 'react'
import styles from './DetailBlockWidget.module.css'
import { Sns, snsImage } from '../../../logic/ValueFormatters'

interface CompanySocialMediaDetailBlockProps {
  linkedin_url?: string
  twitter_url?: string
  facebook_url?: string
  instagram_url?: string
  pinterest_url?: string
  email?: string
  size: number
  style?: CSSProperties
  className?: string
}

const formatURL = (url: string | undefined): string | undefined => {
  if (!url) return undefined
  return url.startsWith('http') ? url : `https://${url}`
}

export const CompanySocialMediaDetailBlock: FC<CompanySocialMediaDetailBlockProps> = ({
  linkedin_url,
  twitter_url,
  facebook_url,
  instagram_url,
  pinterest_url,
  email,
  size,
  style,
  className,
}) => {
  if (!(linkedin_url || twitter_url || facebook_url || instagram_url || pinterest_url)) {
    return null
  }
  return (
    <div className={`${styles.DetailBlockIcons} ${className || ''}`} style={style}>
      {(
        Object.entries({
          linkedin: linkedin_url,
          twitter: twitter_url,
          email: email,
          facebook: facebook_url,
          instagram: instagram_url,
          pinterest: pinterest_url,
        }) as Array<[Sns, string | undefined]>
      )
        .filter(([_, v]) => v)
        .map(([k, v], i) => (
          <a key={i} href={k === 'email' ? `mailto:${v}` : formatURL(v)} target="_blank" rel="noreferrer noopener">
            {snsImage(k, size)}
          </a>
        ))}
    </div>
  )
}
