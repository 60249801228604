import { Box, BoxProps, Chip, Flex, Group, Space, Text, Tooltip } from '@mantine/core'
import { IconQuestionFill } from './IconQuestionFill'
import React from 'react'

export function WeightInput({
  value,
  onChange,
  error,
  ...rest
}: BoxProps & {
  value: string
  error?: string
  onChange: (value: string) => void
}) {
  return (
    <Box {...rest}>
      <Flex>
        <Text>Weight of importance</Text>
        <Tooltip
          label={
            <Text sx={{ textWrap: 'wrap' }}>
              Please select a weight of importance for this criteria, with 1 being the least important and 5 being the most important. We will train our model
              to prioritize criteria with high weights of importance over those with lower weights of importance.
            </Text>
          }
        >
          <Box>
            <IconQuestionFill />
          </Box>
        </Tooltip>
      </Flex>
      <Space h={8} />
      <Chip.Group multiple={false} value={value} onChange={onChange}>
        <Group spacing="sm">
          <Chip variant="filled" radius="sm" value="1">
            1
          </Chip>
          <Chip variant="filled" radius="sm" value="2">
            2
          </Chip>
          <Chip variant="filled" radius="sm" value="3">
            3
          </Chip>
          <Chip variant="filled" radius="sm" value="4">
            4
          </Chip>
          <Chip variant="filled" radius="sm" value="5">
            5
          </Chip>
        </Group>
      </Chip.Group>
      {error ? (
        <Text c={'red'} size="sm">
          {error}
        </Text>
      ) : null}
    </Box>
  )
}
