import { Button, PasswordInput, Space, Stack, TextInput, Title } from '@mantine/core'
import React, { useState } from 'react'
import { TokyoLayout } from '../../../components/takeoff-tokyo/TokyoLayout'
import { useLocation } from 'react-router'
import { isNotEmpty, useForm } from '@mantine/form'
import { signup, SignupRequest } from '../../../../logic/Backend'
import { useAtom } from 'jotai'
import { tellUsAtom, tellUsInit, tellUsWeightAtom, tellUsWeightInit } from '../../../../store/takeoff-tokyo/tell-us-atom'
import { showErrorToast } from '../../../../logic/Toaster'
import { CheckboxAgreeTerms } from '../../../components/takeoff-tokyo/CheckboxAgreeTerms'

type WelcomeRouteState = {
  email: string
}

export function CreateAccount() {
  const { state } = useLocation<WelcomeRouteState | null>()
  const [, setTellUs] = useAtom(tellUsAtom)
  const [, setTellUsWeight] = useAtom(tellUsWeightAtom)

  const email = state?.email ?? ''
  const form = useForm({
    initialValues: {
      name: '',
      email,
      companyName: '',
      password: '',
      terms: false,
      createdFrom: 'kurrant_meets',
    },
    validate: {
      name: isNotEmpty('required'),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'invalid email'),
      companyName: isNotEmpty('required'),
      password: (value) => (value.length >= 8 ? null : 'password must be at least 8 characters'),
      terms: (value) => (value ? null : 'you must agree to the terms'),
    },
  })

  const [loadingButton, setLoadingButton] = useState(false)

  const handleSubmit = async (values) => {
    setLoadingButton(true)
    try {
      await signup({
        ...values,
        startup_founder: true,
        signupOrigin: 'webapp',
        language: localStorage.getItem('language'),
      } as SignupRequest)
      setTellUs(tellUsInit)
      setTellUsWeight(tellUsWeightInit)
      setLoadingButton(false)
      location.href = '/takeoff-tokyo/startups/find-your-startup'
    } catch (e) {
      showErrorToast('Failed to sign up')
      setLoadingButton(false)
    }
  }

  return (
    <TokyoLayout>
      <Space h="lg" />
      <Title>Nice to meet you!</Title>
      <Space h={24} />
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput label="Full Name" placeholder="" {...form.getInputProps('name')} />
          <TextInput label="Email" placeholder="" {...form.getInputProps('email')} />
          <TextInput label="Company Name" placeholder="" {...form.getInputProps('companyName')} />
          <PasswordInput label="Password (8+ characters)" {...form.getInputProps('password')} />
        </Stack>
        <Space h={24} />
        <CheckboxAgreeTerms {...form.getInputProps('terms')} />
        <Space h={48} />
        <Button loading={loadingButton} size="lg" fullWidth type={'submit'}>
          Create Account
        </Button>
      </form>
    </TokyoLayout>
  )
}
