import { atomWithRefresh } from 'jotai/utils'
import { nestedIndustries } from '../../logic/Backend'

type IndustryItem = {
  id: number
  industry_group: string
  industry: string | null
  sub_industry: string | null
}

async function fetchIndustryData() {
  const data: IndustryItem[] = (await nestedIndustries()) as unknown as IndustryItem[]

  const isIndustryGroup = ({ industry, sub_industry }: IndustryItem) => !industry && !sub_industry
  const industryGroups = data.filter(isIndustryGroup)
  const subIndustries = data.filter((item) => !isIndustryGroup(item))
  const industryMap = [...new Set(subIndustries.map((item) => item.industry))].map((industry) => {
    const industry_group = subIndustries.find((item) => item.industry === industry).industry_group
    return {
      industry,
      industry_group,
    }
  })

  const industryGroupOptions = industryGroups.map((x) => ({
    label: x.industry_group,
    value: x.industry_group,
  }))

  const industryMapOptions = industryMap.map((x) => ({
    label: x.industry,
    value: x.industry,
    group: x.industry_group,
  }))

  const subIndustryOptions = subIndustries.map((x) => ({
    label: x.sub_industry,
    value: String(x.id),
    group: x.industry,
  }))

  const subIndustryLabelOptions = subIndustries.map((x) => ({
    label: x.sub_industry,
    value: x.sub_industry,
    group: x.industry,
    id: String(x.id),
  }))

  function industryGroupPayload(industryGroupNames: string[]) {
    return industryGroups
      .filter((x) => industryGroupNames.includes(x.industry_group))
      .map(({ industry_group, industry, sub_industry }) => ({
        industry_group,
        industry: '',
        sub_industry: '',
      }))
  }

  function subIndustryGroupPayload(subIndustryIds: string[]) {
    const ids = subIndustryIds.map(Number)
    return subIndustries
      .filter((x) => ids.includes(x.id))
      .map(({ industry_group, industry, sub_industry }) => ({
        industry_group,
        industry,
        sub_industry,
      }))
  }

  return {
    industryGroups,
    industryGroupOptions,
    industryMap,
    industryMapOptions,
    subIndustries,
    subIndustryOptions,
    subIndustryLabelOptions,
    industryGroupPayload,
    subIndustryGroupPayload,
  }
}

export const industryDataAtom = atomWithRefresh((get) => fetchIndustryData())
