import { CompanyDetailsCard } from '../../../../../stories/CompanyDetailsCard'
import { Divider, Icon } from '@blueprintjs/core'
import { internationalUnits, relativeDate } from '../../../../../logic/ValueFormatters'
import React, { FC, ReactNode } from 'react'
import { CompanyData } from '../../../../../logic/Backend'
import { withNamespaces, WithNamespaces, Trans } from 'react-i18next'
import styles from './Instagram.module.scss'
export interface InstagramCardProps {
  company?: CompanyData
}

const InstagramCard: FC<InstagramCardProps & WithNamespaces & IncludeT> = ({ company, t }) => {
  const igFromTo = company?.ig_latest_9_posts_daterange?.split(/\.{2,3}/)
  const dateFrom = igFromTo && relativeDate(igFromTo[0])
  const dateTo = igFromTo && relativeDate(igFromTo[1])
  return (
    <CompanyDetailsCard
      title={'Instagram'}
      thingsToDisplay={[
        company?.ig_username,
        company?.ig_business_category_name,
        company?.ig_full_name,
        company?.ig_is_verified,
        company?.ig_posts_count_integer,
        company?.ig_followers_count_integer,
        company?.ig_following_count_integer,
        company?.ig_biography,
        company?.ig_external_url,
        company?.ig_latest_9_posts_max_likes_date,
      ]}
      naLinkMessage={'Visit Instagram'}
    >
      <div className={styles.container}>
        <div className={styles.leftColumn}>
          <div className={styles.nameContainer}>
            <span>
              {company?.ig_full_name} {company?.ig_is_verified && <Icon icon={'endorsed'} color={'#0575ac'} />}
            </span>
            <a href={`https://www.instagram.com/${company?.ig_username}/`} target="_blank" rel="noopener noreferrer">{`@${company?.ig_username}`}</a>
          </div>
          <div className={styles.statsContainer}>
            <span>
              <strong className={styles.statsText}>{internationalUnits(company?.ig_posts_count_integer, 1, '', 'n/a')}</strong> Posts
            </span>
            <span>
              <strong className={styles.statsText}>{internationalUnits(company?.ig_followers_count_integer, 1, '', 'n/a')}</strong> Followers
            </span>
            <span>
              <strong className={styles.statsText}>{internationalUnits(company?.ig_following_count_integer, 1, '', 'n/a')}</strong> Following
            </span>
          </div>
          {company?.ig_biography && <div>{company?.ig_biography}</div>}
          {company?.ig_external_url && (
            <div>
              <a href={company?.ig_external_url} target="_blank" rel="noopener noreferrer">
                {company?.ig_external_url}
              </a>
            </div>
          )}
        </div>
        {company?.ig_latest_9_posts_max_likes_date && (
          <>
            {/* <Divider className={styles.divider} /> */}
            <div className={styles.rightColumn}>
              <div>
                <strong>{t('Quick Summary')}</strong> {t('Last 9 Posts')}
              </div>
              <div className={styles.rightColumn}>
                {igFromTo && (
                  <div className={styles.statsRow}>
                    <Icon icon={'time'} color={'#5C7680'} />
                    {igFromTo ? (
                      <Trans i18nKey="post_range">
                        Posts range from <strong>{{ dateFrom }}</strong> to <strong> {{ dateTo }}</strong>
                      </Trans>
                    ) : (
                      <span>No Information on Instagram Posts</span>
                    )}
                  </div>
                )}
                {company?.ig_latest_9_posts_max_likes_date && company?.ig_latest_9_posts_max_likes && (
                  <div className={styles.statsRow}>
                    <Icon icon={'heart'} color={'#5C7680'} />
                    <div>
                      {t('Most Likes')} <strong>{relativeDate(company?.ig_latest_9_posts_max_likes_date)}</strong> (
                      {internationalUnits(company?.ig_latest_9_posts_max_likes, 1)} {t('Likes')})
                    </div>
                  </div>
                )}
                {company?.ig_latest_9_posts_max_comments_date && company?.ig_latest_9_posts_max_comments && (
                  <div className={styles.statsRow}>
                    <Icon icon={'comment'} color={'#5C7680'} />
                    <div>
                      {t('Most Comments')} <strong>{relativeDate(company?.ig_latest_9_posts_max_comments_date)}</strong> (
                      {internationalUnits(company?.ig_latest_9_posts_max_comments, 1)} {t('Comments')})
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </CompanyDetailsCard>
  )
}

export default withNamespaces()(InstagramCard)
