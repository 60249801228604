import React, { useEffect } from 'react'
import { TokyoLayout } from '../../../../components/takeoff-tokyo/TokyoLayout'
import { Button, Flex, MultiSelect, Stack, Text } from '@mantine/core'
import { SubTitle } from '../../../../components/takeoff-tokyo/SubTitle'
import { WeightInput } from '../../../../components/takeoff-tokyo/WeightInput'
import { useHistory } from 'react-router-dom'
import { useAtom } from 'jotai'
import { tellUsAtom, tellUsWeightAtom } from '../../../../../store/takeoff-tokyo/tell-us-atom'
import { useForm } from '@mantine/form'
import { FUNDING_TYPES } from '../../../../../resources/reference_data/funding_types'

const FUNDING_TYPE_OPTIONS = Object.entries(FUNDING_TYPES).map(([k, v]) => ({
  value: k,
  label: v,
}))

export function FundingStage() {
  const [tellUs, setTellUs] = useAtom(tellUsAtom)
  const [tellUsWeight, setTellUsWeight] = useAtom(tellUsWeightAtom)
  const history = useHistory()

  const form = useForm({
    initialValues: {
      fundingStage: [],
      weight: '',
    },
    // validate: {
    //   fundingStage: (value) => (value.length > 0 ? null : 'Required'),
    // },
  })

  useEffect(() => {
    form.setValues({
      fundingStage: tellUs.fundingStage,
      weight: tellUsWeight.fundingStage,
    })
  }, [tellUs, tellUsWeight])

  const onSubmit = form.onSubmit((values) => {
    setTellUs((tellUs) => ({
      ...tellUs,
      fundingStage: values.fundingStage,
    }))
    setTellUsWeight((tellUsWeight) => ({
      ...tellUsWeight,
      fundingStage: values.weight,
    }))
    history.push('/takeoff-tokyo/investors/tell-us/total-funding-amount')
  })

  return (
    <TokyoLayout progress={40}>
      <SubTitle>Funding stage</SubTitle>
      <Text>Which funding stage are you usually participating in?</Text>
      <Text>通常、どの資金調達ステージに参加していますか？</Text>
      <form onSubmit={onSubmit}>
        <Stack>
          <MultiSelect searchable data={FUNDING_TYPE_OPTIONS} label="Funding stage" placeholder="" size="lg" {...form.getInputProps('fundingStage')} />
          <WeightInput {...form.getInputProps('weight')} />
          <Flex justify={'space-between'}>
            <Button variant={'outline'} onClick={() => history.push('/takeoff-tokyo/investors/tell-us/countries')}>
              Back
            </Button>
            <Button type={'submit'}>Next</Button>
          </Flex>
        </Stack>
      </form>
    </TokyoLayout>
  )
}
