import { BackendCall, callBackend, GetQuicksearchCompaniesType } from '../../logic/Backend'
import { QuickSearchCompaniesType } from '../../ui/pages/Dashboard/SearchBar'

export const getQuickSearch: BackendCall<
  GetQuicksearchCompaniesType,
  { companies: QuickSearchCompaniesType[]; from: number; limit: number; total_count: number }
> = async ({ query, from, limit }, abortSignal) => {
  return await callBackend('get', `organization_claims/quick_search?query=${query}&from=${from}&limit=${limit}`, 200, undefined, (r) => r.json(), abortSignal)
}

type StartupClaim = {
  organization_id: string
}
export const createStartupClaim: BackendCall<StartupClaim, any> = async (payload, abortSignal) => {
  return await callBackend('post', 'organization_claims', 201, payload, (r) => r.json(), abortSignal)
}
