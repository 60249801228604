import { Anchor, Text, TextProps } from '@mantine/core'
import React from 'react'

export function PoweredBy(props: TextProps) {
  return (
    <Text c="gray" size="md" {...props}>
      Powered by
      <Anchor href="https://www.kurrant.ai" target="_blank" c="gray" td="underline" fw={700} px={'.5ex'}>
        Kurrant.AI
      </Anchor>
    </Text>
  )
}
