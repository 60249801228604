import React, { FC, ReactElement, useState } from 'react'
import { CompanyDataPoint } from '../ui/pages/Company/CompanyDataPoint'
import { DegreeData, EmployeeData, JobData } from '../logic/Backend'
import { year } from '../logic/ValueFormatters'
import { CompanySocialMediaDetailBlock } from '../ui/components/DetailBlockWidget/CompanySocialMediaDetailBlock'
import { Button, Divider } from '@blueprintjs/core'
import { EmployeeHistoryPoint } from './EmployeeHistoryPoint'
import palette from '../_palette.module.scss'
import { CbLiOrPlaceholderImage } from '../ui/pages/Company/CbLiOrPlaceholderImage'

export const KeyContact: FC<EmployeeData> = ({
  cb_profile_pic_url,
  li_profile_pic_url,
  role,
  name,
  description,
  facebook_url,
  linkedin_url,
  twitter_url,
  email,
  jobs,
  degrees,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const divider = <Divider style={{ marginLeft: '-24px', marginRight: '-24px' }} />

  function datesToDateRange(from: string | undefined, to: string | undefined, ongoing: boolean | undefined): string | undefined {
    if (from == undefined && to == undefined) {
      return undefined
    }
    return `${year(from, '?')} - ${ongoing ? 'Present' : year(to, '?')}`
  }

  function degreeText(type: string | undefined, title: string | undefined): string | undefined {
    if (type == undefined && title == undefined) {
      return undefined
    }
    if (type === 'unknown') {
      type = undefined
    }
    return `${type ?? ''} ${title ?? ''}`
  }

  function Timeline<E extends JobData | DegreeData>(title: string, list: Array<E> | undefined, fn: (element: E, i: number) => ReactElement): ReactElement {
    if (list?.length && list.length > 0) {
      return (
        <>
          {divider}
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>{title}</div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '32px', position: 'relative' }}>
            <div
              style={{
                borderLeft: '1px dashed',
                borderColor: palette.blue3,
                position: 'absolute',
                left: '8px',
                top: '28px',
                bottom: '28px',
              }}
            />
            {list.map((e, i) => fn(e, i))}
          </div>
        </>
      )
    }
    return <></>
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        // gap: '24px',
        backgroundColor: 'white',
        // boxShadow: '0 2px 4px 1px RGBA(233, 233, 233, 0.75)',
        padding: '24px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          // gap: '16px',
        }}
      >
        <CbLiOrPlaceholderImage
          size={50}
          logo_id={cb_profile_pic_url}
          li_logo_url={li_profile_pic_url}
          style={{ borderRadius: '50%' }}
          altText={'Profile picture'}
        />
        <CompanyDataPoint label={role ?? '-'} value={name} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            gap: '5px',
            marginLeft: 'auto',
            marginBottom: 'auto',
          }}
        >
          {/* <CompanySocialMediaDetailBlock
            linkedin_url={linkedin_url}
            twitter_url={twitter_url}
            facebook_url={facebook_url}
            email={email}
            size={16}
            style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '4px', direction: 'rtl' }}
          /> */}
        </div>
      </div>
      {/* <Button
        minimal={true}
        text={'See Details, Job, and Education'}
        rightIcon={'chevron-down'}
        style={{ marginLeft: 'auto', marginTop: '-8px', marginBottom: '-8px' }}
        onClick={() => setIsExpanded(!isExpanded)}
        disabled={description == undefined && jobs == undefined && degrees == undefined}
      />
      {isExpanded && (
        <>
          {description != undefined && (
            <>
              {divider}
              <div>{description}</div>
            </>
          )}
          {Timeline<JobData>('Jobs', jobs, (job, i) => (
            <EmployeeHistoryPoint
              key={i}
              label1={job.organization_name}
              label2={job.title}
              label3={datesToDateRange(job.started_on, job.ended_on, job.is_current)}
              isCurrent={job.is_current}
              logo_id={job.organization_logo_id}
              li_logo_url={job.organization_li_logo_url}
            />
          ))}
          {Timeline<DegreeData>('Education', degrees, (degree, i) => (
            <EmployeeHistoryPoint
              key={i}
              label1={degree.organization_name}
              label2={degreeText(degree.degree_type, degree.title)}
              label3={datesToDateRange(degree.started_on, degree.completed_on, degree.is_completed === false)}
              isCurrent={degree.is_completed === false}
              logo_id={degree.organization_logo_id}
              li_logo_url={degree.organization_li_logo_url}
            />
          ))}
        </>
      )} */}
    </div>
  )
}
