import { Box, Button, Text, Title } from '@mantine/core'
import { IconArrowForward } from '../../IconArrowForward'
import React from 'react'
import { TokyoLayout } from '../../../components/takeoff-tokyo/TokyoLayout'
import { useHistory } from 'react-router-dom'

export function ThankForUpdate() {
  const history = useHistory()
  return (
    <TokyoLayout>
      <Title>Thank you for your updates!</Title>
      <Text>
        Our team will review the submitted data and update your company profile.
        <br /> Next, please set your availability for meeting with investors.
      </Text>
      <Text>
        弊社チームがご提出いただいた情報を確認し、貴社の企業プロフィールを更新いたします。
        <br />
        その後、投資家との商談に向けて、ご都合の良い日時をご設定ください。
      </Text>
      <Box>
        <Button onClick={() => history.push('/takeoff-tokyo/startups/availability')}>
          Set Meeting Availability
          <IconArrowForward />
        </Button>
      </Box>
    </TokyoLayout>
  )
}
