import React, { useEffect, useState } from 'react'
import { ControlGroup } from '@blueprintjs/core'
import Filter from './Filter'
import styles from './style.module.scss'
import { t } from 'i18next'
import i18next from 'i18next'
import { filterValueTranslations } from './translations'
import { getNewsFilters } from '../../../../logic/Backend'

interface Props {
  onFilterSelected: (key: string, value: string) => void
  handleClear: () => void
  shouldClearFilter: boolean
  resetClearFilter: () => void
}

const FilterContainer = ({ onFilterSelected, handleClear, shouldClearFilter, resetClearFilter }: Props) => {
  const [filters, setFilters] = useState({})

  const filterGroups = {
    industry: t('Industries'),
    theme: t('Themes'),
    article_type: t('Type'),
    geography: t('Geography'),
  }

  const getTranslatedLabel = (filter: string, value: string) => {
    // Only translate if language is Japanese
    if (i18next.language === 'jp') {
      return filterValueTranslations[filter]?.[value] || value
    }
    return value
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getNewsFilters()
        setFilters(res)
        return res
      } catch (e) {
        console.log('Error fetching news filters: ', e)
      }
    }
    getData()
  }, [])

  return (
    <div className={styles.filterContainer}>
      <span>{t('Filter by:')}</span>
      <ControlGroup>
        {Object.keys(filterGroups).map((filter, i) => {
          const optionsFromValues = filters[filter]?.map((value) => ({
            value, // Original value for backend
            label: getTranslatedLabel(filter, value), // Translated value for display
          }))

          return (
            <Filter
              key={i}
              category={filter}
              label={filterGroups[filter]}
              values={optionsFromValues || []}
              onFilterSelected={onFilterSelected}
              shouldClearFilter={shouldClearFilter}
              resetClearFilter={resetClearFilter}
            />
          )
        })}
      </ControlGroup>
      <span className={`${styles.alignRight} ${styles.clickableLink}`} onClick={handleClear}>
        {t('Clear Filters')}
      </span>
    </div>
  )
}

export default FilterContainer
