import { atomWithStorage } from 'jotai/utils'

export type NumberInputValue = number | ''
export type YearRange = [Date | null, Date | null]

type TellUs = {
  industries: {
    industry_group: string
    industry: string
    sub_industry: string
  }[]
  countries: string[]
  fundingStage: string[]
  totalFundingAmount: NumberInputValue[]
  employeeCount: NumberInputValue[]
  yearFounded: YearRange
  industryGroups: string[]
  industriesMaps: string[]
  subIndustries: string[]
}

export const tellUsInit: TellUs = {
  industries: [],
  countries: [],
  fundingStage: [],
  totalFundingAmount: [],
  employeeCount: [],
  yearFounded: [null, null],

  // industries form input values
  industryGroups: [],
  industriesMaps: [],
  subIndustries: [],
}
export const tellUsAtom = atomWithStorage<TellUs>('tellUsAtom', tellUsInit)

export const tellUsWeightInit = {
  industries: '3',
  countries: '3',
  fundingStage: '3',
  totalFundingAmount: '3',
  employeeCount: '3',
  yearFounded: '3',
}
export const tellUsWeightAtom = atomWithStorage('tellUsWeightAtom', tellUsWeightInit)
