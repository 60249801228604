import React from 'react'
import { CompanyDetailsCard } from '../../../../../stories/CompanyDetailsCard'
import { CompanyDataPoint } from '../../CompanyDataPoint'
import { CompanyData } from '../../../../../logic/Backend'
import { year, rangeString, flagAndCountryName, CountryFormat, salesTargetParser, revenueParser } from '../../../../../logic/ValueFormatters'
import styles from './BasicsCard.module.scss'

interface BasicsCardProps {
  company?: CompanyData
  t: (key: string) => string
  companyFormattedAddress: () => string
}

export const InsightsCard: React.FC<BasicsCardProps> = ({ company, t, companyFormattedAddress }) => {
  return (
    <CompanyDetailsCard title={t('Insights')}>
      <div className={styles.dataPointsGrid} id="Insights">
        {company?.sub_region && <CompanyDataPoint label={t('Geography Mapping')} value={company.sub_region} />}

        {company?.industries && (
          <CompanyDataPoint label={t('Industry')} value={Array.isArray(company.industries) ? company.industries.join(' > ') : company.industries} />
        )}

        {company?.sales_target_type && <CompanyDataPoint label={t('Sales Target')} value={salesTargetParser(company.sales_target_type)} />}

        {company?.revenue_stream_type && <CompanyDataPoint label={t('Revenue Stream')} value={revenueParser(company.revenue_stream_type)} />}

        {company?.founded_on_date && <CompanyDataPoint label={t('Founded')} value={year(company.founded_on_date, 'n/a')} />}

        {company?.employee_count_int4range && (
          <CompanyDataPoint label={t('Employee Range')} value={rangeString(company.employee_count_int4range, undefined, 'n/a')} />
        )}

        {company?.country_iso_3166_1_alpha_3 && (
          <CompanyDataPoint label={t('Country')} value={flagAndCountryName(company.country_iso_3166_1_alpha_3, CountryFormat.Code) ?? 'n/a'} />
        )}

        {company?.investors && <CompanyDataPoint label={t('Investor')} value={company.investors} />}
      </div>
      {companyFormattedAddress() !== 'n/a' && <CompanyDataPoint label={t('Address')} value={companyFormattedAddress()} className={styles.address} />}
    </CompanyDetailsCard>
  )
}
