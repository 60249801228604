import React, { FC } from 'react'
import styles from './Header.module.scss'
import { CompanyName } from '../../CompanyName'
import { UserRatingPicker } from '../../../../../stories/UserRatingPicker'
import CompanyStatusDropDown from '../../../Pipeline/components/CompanyStatusDropDown'
import { InterpretedHtml } from '../../../../../logic/ValueFormatters'
import { CompanySocialMediaDetailBlock } from '../../../../components/DetailBlockWidget/CompanySocialMediaDetailBlock'
import { CbLiOrPlaceholderImage } from '../../CbLiOrPlaceholderImage'
import { CompanyDataPoint } from '../../CompanyDataPoint'
import { CompanyData } from '../../../../../logic/Backend'
import { internationalUnits, flagAndCountryName, CountryFormat, mailtoLinks } from '../../../../../logic/ValueFormatters'
import { OPERATING_STATUS_MAP } from '../../../../../resources/reference_data/operating_statuses'

interface HeaderProps {
  company: CompanyData | undefined
  companyName: string
  link: string | undefined
  linkedinUrl: string | undefined
  organizationId: string | null
  pipelineStatus: string | undefined
  t: (key: string) => string
}

export const Header: FC<HeaderProps> = ({ company, companyName, link, linkedinUrl, organizationId, pipelineStatus, t }) => {
  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.headerFlexWrap}>
          <div>
            <div className={styles.companyHeaderFlex}>
              <CbLiOrPlaceholderImage logo_id={company?.logo_id} li_logo_url={company?.li_logo_url} className={styles.companyLogo} altText={'Logo'} />
              <div className={styles.companyNameContainer}>
                <div className={styles.companyNameFlex}>
                  <CompanyName name={companyName} link={link} />
                  {company && <UserRatingPicker company={company} rating={company.rating} large={true} />}
                  <CompanyStatusDropDown organization_id={organizationId} pipeline_status={pipelineStatus} />
                </div>
                {company?.emails && (
                  <div className={styles.emailContainer}>
                    <InterpretedHtml html={mailtoLinks(company?.emails)} />
                  </div>
                )}
                <CompanySocialMediaDetailBlock
                  linkedin_url={linkedinUrl}
                  twitter_url={company?.twitter_url}
                  facebook_url={company?.facebook_url}
                  instagram_url={company?.ig_username ? company?.instagram_url : undefined}
                  pinterest_url={company?.pinterest_url}
                  size={22}
                  className={`${styles.socialMediaBlock} ${company?.emails ? styles.hasEmails : ''}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
