import { Button, Flex, Input, NumberInput, Stack, Text } from '@mantine/core'
import { SubTitle } from '../../../../components/takeoff-tokyo/SubTitle'
import React, { useEffect } from 'react'
import { WeightInput } from '../../../../components/takeoff-tokyo/WeightInput'
import { useHistory } from 'react-router-dom'
import { TokyoLayout } from '../../../../components/takeoff-tokyo/TokyoLayout'
import { useAtom } from 'jotai'
import { NumberInputValue, tellUsAtom, tellUsWeightAtom } from '../../../../../store/takeoff-tokyo/tell-us-atom'
import { useForm } from '@mantine/form'

export function EmployeeCount() {
  const [tellUs, setTellUs] = useAtom(tellUsAtom)
  const [tellUsWeight, setTellUsWeight] = useAtom(tellUsWeightAtom)
  const history = useHistory()

  const form = useForm<{
    from: NumberInputValue
    to: NumberInputValue
    weight: string
  }>({
    initialValues: {
      from: '',
      to: '',
      weight: '',
    },
    // validate: {
    //   from: (value) => (value ? null : 'Required'),
    //   to: (value) => (value ? null : 'Required'),
    // },
  })

  useEffect(() => {
    form.setValues({
      from: tellUs.employeeCount[0] || '',
      to: tellUs.employeeCount[1] || '',
      weight: String(tellUsWeight.employeeCount),
    })
  }, [tellUs, tellUsWeight])

  const onSubmit = form.onSubmit((values) => {
    setTellUs((tellUs) => ({
      ...tellUs,
      employeeCount: [values.from, values.to],
    }))
    setTellUsWeight((tellUsWeight) => ({
      ...tellUsWeight,
      employeeCount: values.weight,
    }))
    history.push('/takeoff-tokyo/investors/tell-us/year-founded')
  })

  return (
    <TokyoLayout progress={80}>
      <SubTitle>Employee Count</SubTitle>
      <Text>Select the company size of your target companies.</Text>
      <Text>投資対象企業の規模を選択してください。</Text>
      <form onSubmit={onSubmit}>
        <Stack>
          <Input.Wrapper label={'Employee range between'}>
            <Flex align={'center'} justify={'space-between'}>
              <NumberInput placeholder="1" hideControls {...form.getInputProps('from')} />
              <Text px={'1.5ex'}>and</Text>
              <NumberInput placeholder="100" hideControls {...form.getInputProps('to')} />
            </Flex>
          </Input.Wrapper>
          <WeightInput {...form.getInputProps('weight')} />
          <Flex justify={'space-between'}>
            <Button variant={'outline'} onClick={() => history.push('/takeoff-tokyo/investors/tell-us/funding-stage')}>
              Back
            </Button>
            <Button type={'submit'}>Next</Button>
          </Flex>
        </Stack>
      </form>
    </TokyoLayout>
  )
}
