import { createModel } from '@rematch/core'
import { RootModel } from '.'
import { SelectedIndustryGroups } from '../stories/Filtering/Filters/MultiSelect'

export type NestedIndustryForBackend = {
  industry_group: string
  industry: string
  sub_industry: string
}

export type NestedIndustryData = {
  allItems: SelectedIndustryGroups[]
  itemsToDisplay: SelectedIndustryGroups[]
  nestedIndustryNames: string[]
  objectForBackend: NestedIndustryForBackend[]
}

const initialState: NestedIndustryData = { allItems: [], itemsToDisplay: [], nestedIndustryNames: [], objectForBackend: [] }
const selectedNestedIndustryState = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    updateAllItems(state, payload) {
      // change nestedIndustryNames
      state.allItems = payload.newItems
      state.itemsToDisplay = payload.itemsToDisplay
      state.nestedIndustryNames = state.itemsToDisplay.map((ind) => ind.name)
      return state
    },
    updateObjectForBackend(state, payload) {
      state.objectForBackend = payload.objectForBackend
      return state
    },
    updateStateWithSavedFilters(state, payload) {
      if (!payload?.objectForBackend || !payload?.nestedIndustries) {
        return state
      }
      const savedFilterState: NestedIndustryForBackend[] = payload.objectForBackend
      const allIndustries: SelectedIndustryGroups[] = payload.nestedIndustries || []
      state.objectForBackend = savedFilterState

      // populate allItems first...
      const newAllItems = savedFilterState.map((obj) => {
        const newItem = []
        if (obj?.industry_group) {
          const ig = allIndustries.find((indGroup) => indGroup?.name === obj.industry_group)
          if (ig) {
            newItem.push(ig)
            if (obj.industry && ig.industries) {
              const industry = ig.industries.find((ind) => ind?.name === obj.industry)
              if (industry) {
                newItem.push(industry)
                if (obj.sub_industry && industry.subIndustries) {
                  const subIndustry = industry.subIndustries.find((si) => si?.name === obj.sub_industry)
                  if (subIndustry) {
                    newItem.push(subIndustry)
                  }
                }
              }
            }
          }
        }
        return newItem
      })
      state.allItems = newAllItems.flat() as SelectedIndustryGroups[]

      const newItemsToDisplay = savedFilterState
        .map((obj) => {
          let ig: SelectedIndustryGroups | undefined
          let industry: SelectedIndustryGroups | undefined
          let subInd: SelectedIndustryGroups | undefined

          if (obj?.industry_group) {
            ig = allIndustries.find((indGroup) => indGroup?.name === obj.industry_group)
            if (ig?.industries && obj.industry) {
              industry = ig.industries.find((ind) => ind?.name === obj.industry)
              if (industry?.subIndustries && obj.sub_industry) {
                subInd = industry.subIndustries.find((si) => si?.name === obj.sub_industry)
              }
            }
          }

          return subInd || industry || ig || null
        })
        .filter(Boolean) as SelectedIndustryGroups[]

      state.itemsToDisplay = newItemsToDisplay
      state.nestedIndustryNames = newItemsToDisplay.map((ind) => ind?.name || '')
      return state
    },
    clearIndustries(state, payload) {
      state.nestedIndustryNames = []
      state.allItems = []
      state.itemsToDisplay = []
      state.nestedIndustryNames = []
      state.objectForBackend = []
      return state
    },
    // handle state changes with pure functions
  },
  effects: (dispatch) => ({
    // trigger dispatch
    // handle state changes with impure functions.
    // use async/await for async actions
  }),
})

export default selectedNestedIndustryState
