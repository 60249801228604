import { Box, Center, Container, Flex, Loader, Paper, ScrollArea, Space } from '@mantine/core'
import React, { ReactNode } from 'react'
import { PoweredBy } from './PoweredBy'
import { LogoKurrantAi } from './LogoKurrantAi'

export function TokyoFormLayout({ children, debug }: { children: ReactNode; debug?: ReactNode; progress?: number }) {
  return (
    <Flex bg={{ xs: '#F6F8FA' }} w={'100%'}>
      <Box
        sx={{
          flexGrow: 1,
          height: '100%',
        }}
      >
        <ScrollArea h={'100%'} w={'100%'}>
          <Center
            sx={(theme) => {
              const xs = `@media (min-width: ${theme.breakpoints.xs})`
              return {
                display: 'none',
                [xs]: {
                  display: 'flex',
                  marginTop: '80px',
                },
              }
            }}
          >
            <LogoKurrantAi />
          </Center>

          <Space h={24} />

          <Container>
            <Paper
              sx={(theme) => {
                const xs = `@media (min-width: ${theme.breakpoints.xs})`
                return {
                  border: 'none',
                  padding: '8px',
                  [xs]: {
                    border: '1px solid #48484933',
                    padding: '48px',
                  },
                }
              }}
            >
              {children}
            </Paper>
          </Container>
          <Space h={24} />
          <Center>
            <PoweredBy />
          </Center>
          <Space h={24} />
        </ScrollArea>
      </Box>
    </Flex>
  )
}

export function TokyoFormLayoutLoader() {
  return (
    <TokyoFormLayout>
      <Center mih={'60vh'}>
        <Loader size="lg" color={'brand'} />
      </Center>
    </TokyoFormLayout>
  )
}
