import React, { useEffect } from 'react'
import { TokyoLayout } from '../../../../components/takeoff-tokyo/TokyoLayout'
import { Button, Flex, MultiSelect, Stack, Text } from '@mantine/core'
import { SubTitle } from '../../../../components/takeoff-tokyo/SubTitle'
import { WeightInput } from '../../../../components/takeoff-tokyo/WeightInput'
import { useHistory } from 'react-router-dom'
import { useAtom } from 'jotai'
import { tellUsAtom, tellUsWeightAtom } from '../../../../../store/takeoff-tokyo/tell-us-atom'
import { useForm } from '@mantine/form'
import { COUNTRIES_MAP } from '../../../../../resources/reference_data/countries'

const COUNTRY_OPTIONS = Object.entries(COUNTRIES_MAP).map(([k, v]) => ({
  value: k,
  label: v.join(' '),
}))

export function Countries() {
  const [tellUs, setTellUs] = useAtom(tellUsAtom)
  const [tellUsWeight, setTellUsWeight] = useAtom(tellUsWeightAtom)
  const history = useHistory()

  const form = useForm({
    initialValues: {
      countries: [],
      weight: '',
    },
    validate: {
      countries: (value) => (value.length > 0 ? null : 'Required'),
    },
  })

  useEffect(() => {
    form.setValues({
      countries: tellUs.countries,
      weight: tellUsWeight.countries,
    })
  }, [tellUs, tellUsWeight])

  const onSubmit = form.onSubmit((values) => {
    setTellUs((tellUs) => ({
      ...tellUs,
      countries: values.countries,
    }))

    setTellUsWeight((tellUsWeight) => ({
      ...tellUsWeight,
      countries: values.weight,
    }))
    history.push('/takeoff-tokyo/investors/tell-us/funding-stage')
  })

  return (
    <TokyoLayout progress={20}>
      <SubTitle>Countries</SubTitle>
      <Text>Select the geographical areas you are targeting or looking to invest in.</Text>
      <Text>投資対象として関心のある地域を選択してください。</Text>
      <form onSubmit={onSubmit}>
        <Stack>
          <MultiSelect searchable data={COUNTRY_OPTIONS} label="Countries" placeholder="" size="lg" {...form.getInputProps('countries')} />
          <WeightInput {...form.getInputProps('weight')} />
          <Flex justify={'space-between'}>
            <Button variant={'outline'} onClick={() => history.push('/takeoff-tokyo/investors/tell-us/industries')}>
              Back
            </Button>
            <Button type={'submit'}>Next</Button>
          </Flex>
        </Stack>
      </form>
    </TokyoLayout>
  )
}
