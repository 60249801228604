import React from 'react'
import { Anchor, Button, Stack, Text, TextInput, Title } from '@mantine/core'
import { IconArrowForward } from '../../../components/takeoff-tokyo/IconArrowForward'
import { TokyoLayout } from '../../../components/takeoff-tokyo/TokyoLayout'
import { useHistory } from 'react-router-dom'
import { useForm } from '@mantine/form'

export function Welcome() {
  const history = useHistory()

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  return (
    <TokyoLayout>
      <Title>Welcome!</Title>
      <Text>
        Welcome to Takeoff Tokyo with the help of Kurrant.ai! Get in front of investors actively seeking innovative startups like yours. Fill in your details,
        set your availability, and let our smart matching system connect you with the right investors. Let’s turn conversations into opportunities and fuel your
        startup’s growth!
      </Text>
      <Text>
        Takeoff Tokyoへようこそ！
        Kurrant.aiのサポートを受けながら、イノベーティブなスタートアップ企業を求める投資家とつながりましょう。必要情報を入力し、希望の日時を設定するだけで、スマートマッチングシステムが最適な投資家をご紹介します。
        対話をビジネスチャンスへとつなげ、スタートアップ企業の成長を加速させましょう！
      </Text>
      <form
        onSubmit={form.onSubmit((values) => {
          const { email } = values
          history.push('/takeoff-tokyo/startups/create-account', {
            email,
            startupFounder: true,
          })
        })}
      >
        <Stack>
          <TextInput placeholder="kuzu@some.startup" label={'Email'} {...form.getInputProps('email')} />
          <Button type="submit">
            Let&apos;s get started
            <IconArrowForward />
          </Button>
        </Stack>
      </form>
      <Text ta="right">
        Already signed up? <Anchor href="/takeoff-tokyo/startups/welcome-back">Click here</Anchor>
      </Text>
    </TokyoLayout>
  )
}
