import { atomWithRefresh } from 'jotai/utils'
import { currentUserAtom } from './current-user-atom'
import { BackendCall, callBackend } from '../../logic/Backend'

type TimeRange = {
  time_from: string
  time_to: string
}

export type AvailabilitySlotsPayload = {
  user_id: string
  time_ranges: TimeRange[]
}

type AvailabilitySlot = {
  id: string
  user_id: string
  is_available: true
  booked_by_user_id: null | string
  time_from: string
  time_until: string
}

export type AvailabilitySlotsResponse = {
  availability_slots: AvailabilitySlot[]
  merged_slots: [AvailabilitySlot['time_from'], AvailabilitySlot['time_until']][]
}

export const availabilityAtom = atomWithRefresh(async (get) => {
  const user = await get(currentUserAtom)
  if (!user) return null
  return await availabilitySlots()
})

export const availabilitySlots: BackendCall<undefined, AvailabilitySlotsResponse> = async (undefined, abortSignal) => {
  return await callBackend('get', 'user_availability_slots', 200, undefined, (r) => r.json(), abortSignal)
}

export const createAvailabilitySlots: BackendCall<AvailabilitySlotsPayload, any> = async (payload, abortSignal) => {
  return await callBackend('post', 'user_availability_slots', 200, payload, (r) => r.json(), abortSignal)
}

export const updateAvailabilitySlots: BackendCall<AvailabilitySlotsPayload, any> = async (payload, abortSignal) => {
  return await callBackend('put', `user_availability_slots/${payload.user_id}`, 200, payload, (r) => r.json(), abortSignal)
}
