import React, { Suspense } from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { CreateAccount } from './create-account'
import { FindYourStartup } from './find-your-startup'
import { ThankForUpdate } from './thank-for-update'
import { EditStartupProfile } from './edit-startup-profile'
import { Availability } from './availability'
import { VerifyEmail } from './verify-email'
import { Welcome } from './welcome'
import { TokyoFormLayoutLoader } from '../../../components/takeoff-tokyo/TokyoFormLayout'
import { WelcomeBack } from './welcome-back'
import { useAtom } from 'jotai'
import { currentUserAtom } from '../../../../store/takeoff-tokyo/current-user-atom'

export function StartupsRoute() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/welcome`}>
        <Welcome />
      </Route>
      <Route exact path={`${path}/create-account`}>
        <CreateAccount />
      </Route>
      <StartupsUserRoute>
        <Route exact path={`${path}/find-your-startup`}>
          <FindYourStartup />
        </Route>
        <Route exact path={`${path}/welcome-back`}>
          <WelcomeBack />
        </Route>
        <Route exact path={`${path}/thank-for-update`}>
          <ThankForUpdate />
        </Route>
        <Route exact path={`${path}/edit-startup-profile`}>
          <Suspense fallback={<TokyoFormLayoutLoader />}>
            <EditStartupProfile />
          </Suspense>
        </Route>
        <Route exact path={`${path}/availability`}>
          <Availability />
        </Route>
        <Route exact path={`${path}/verify-email`}>
          <VerifyEmail />
        </Route>
      </StartupsUserRoute>
    </Switch>
  )
}

function StartupsUserRoute({ children, ...rest }) {
  const [currentUser] = useAtom(currentUserAtom)
  const { created_from, startup_founder } = currentUser

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!currentUser)
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          )

        if (created_from === 'kurrant_meets' && !startup_founder) {
          return (
            <Redirect
              to={{
                pathname: '/takeoff-tokyo/investors/welcome-back',
                state: { from: location },
              }}
            />
          )
        }
        if (created_from === 'kurrant_meets' && startup_founder) return <>{children}</>
        return <Redirect to={'/'} />
      }}
    />
  )
}
