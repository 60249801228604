import React, { ChangeEventHandler, FC, FormEvent, useState } from 'react'
import { Button, Classes, Dialog, InputGroup, Intent, Label } from '@blueprintjs/core'
import styles from '../app/App.module.scss'
import * as Backend from '../../logic/Backend'
import { showErrorToast } from '../../logic/Toaster'
import * as Routes from '../../logic/Routes'
import { PasswordButton } from '../components/PasswordButton'
import { PlainPageTemplate } from './PlainPageTemplate'
import { routerHistory } from '../app/App'
import { withNamespaces, WithNamespaces } from 'react-i18next'
import { t } from 'i18next'
import { UserData } from '../../logic/Backend'

export interface LoginState {
  email: string
  password: string
  loginOrigin: 'webapp'
}

function dispatchTakeoffTokyoUser(user: UserData) {
  const isTakeoffTokyoInvestor = user.created_from === 'kurrant_meets' && user.startup_founder === false
  const isTakeoffTokyoStartupFonder = user.created_from === 'kurrant_meets' && user.startup_founder === true

  if (isTakeoffTokyoInvestor) {
    if (!user.current_investment_profile_id) return '/takeoff-tokyo/investors/tell-us/industries'
    if (user.current_investment_profile_id) return '/takeoff-tokyo/investors/welcome-back'
  }

  if (isTakeoffTokyoStartupFonder) {
    return '/takeoff-tokyo/startups/welcome-back'
  }

  return '' // not a TakeoffTokyo user
}

const LoginPage: FC<WithNamespaces> = () => {
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [showEmailSent, setShowEmailSent] = useState(false)
  const [state, setState] = useState<LoginState>({ email: '', password: '', loginOrigin: 'webapp' })
  const [resetPasswordEmail, setResetPasswordEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const originalUrl = routerHistory?.location?.state?.from

  const handleEmailChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setState({ ...state, email: e.target.value })
    setResetPasswordEmail(e.target.value)
  }

  const handlePasswordChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setState({ ...state, password: e.target.value })
  }

  async function submitForm(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    try {
      setIsLoading(true)
      const response = await Backend.login(state)
      const user = await Backend.currentUser()
      const takeoffTokyoLink = dispatchTakeoffTokyoUser(user)
      if (takeoffTokyoLink) {
        location.href = takeoffTokyoLink
        return
      }

      if ('stripe_url' in response) {
        location.href = response.stripe_url
        return
      }
      const target = originalUrl === undefined || originalUrl === '/login' ? '/' : originalUrl
      routerHistory.push(target)
    } catch (error) {
      const code = error.message.match(/status: (\d+)/)?.[1]
      if (code === '417') {
        showErrorToast(t('Please check your email inbox to confirm your email address'))
      } else {
        showErrorToast(t('Login failed'))
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function resetPassword() {
    try {
      await Backend.requestPasswordReset({ email: resetPasswordEmail })
      setShowForgotPassword(false)
      setShowEmailSent(true)
    } catch (error) {
      showErrorToast(t('Failed to reset password'))
    }
  }

  function ForgotPasswordDialog() {
    return (
      <Dialog title={t('Forgot Password')} isOpen={showForgotPassword} onClose={() => setShowForgotPassword(false)}>
        <div style={{ margin: '20px 20px 0 20px' }}>
          <Label className={styles.reset_password}>
            <strong>{t('Enter your email to reset your password:')}</strong>
            <InputGroup
              autoFocus={true}
              placeholder={`${t('Email')}`}
              fill={true}
              value={resetPasswordEmail}
              onChange={(e) => setResetPasswordEmail(e.currentTarget.value)}
            />
          </Label>
          <br />
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={() => setShowForgotPassword(!showForgotPassword)} text={t('Cancel')} />
            <Button onClick={() => resetPassword()} intent={Intent.PRIMARY} text={t('Reset Password')} />
          </div>
        </div>
      </Dialog>
    )
  }

  function ForgotPasswordEmailSentDialog() {
    return (
      <Dialog title={t('Email Sent')} isOpen={showEmailSent} onClose={() => setShowEmailSent(false)}>
        <div style={{ margin: '20px 20px 0 20px' }}>
          {t('A Reset Password email has been sent. Please check your inbox and click in the received link to reset your password')}
        </div>
      </Dialog>
    )
  }

  function LoginForm() {
    return (
      <form onSubmit={(e) => submitForm(e)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', width: '100%' }}>
        <InputGroup fill={true} placeholder={`${t('Email')}`} onChange={handleEmailChange} />
        <PasswordButton onChange={handlePasswordChange} />
        <a className={styles.forgot_password_button} onClick={() => setShowForgotPassword(!showForgotPassword)}>
          {t('Forgot Password')}
        </a>
        <Button type={'submit'} className={styles.login_button} intent={Intent.PRIMARY} text={t('Login')} fill={true} loading={isLoading} />
        <p style={{ marginTop: '20px', color: '#999999' }}>
          {t('Don’t have an account?')} <a href={Routes.signup.href}>{t('Sign up now')}</a>
        </p>
      </form>
    )
  }

  return (
    <PlainPageTemplate>
      {LoginForm()}
      {ForgotPasswordDialog()}
      {ForgotPasswordEmailSentDialog()}
    </PlainPageTemplate>
  )
}

export default withNamespaces()(LoginPage)
