import { createModel } from '@rematch/core'
import { RootModel } from '.'

type NewsFilterPayloadType = {
  key: string
  value: string
}

const newsFilterState = createModel<RootModel>()({
  state: { filterState: {}, from: 0, initialLoading: false, paginationLoading: false },
  reducers: {
    updateActiveFilters(state, payload: NewsFilterPayloadType) {
      const newVal = {}
      newVal[payload.key] = payload.value
      return { ...state, filterState: { ...state.filterState, ...newVal } }
    },
    clearAllFilters(state) {
      return { ...state, filterState: {} }
    },
    updateFrom(state, payload) {
      return { ...state, from: payload }
    },
    updateInitialLoading(state, payload) {
      return { ...state, initialLoading: payload }
    },
    updatePaginationLoadaing(state, payload) {
      return { ...state, paginationLoading: payload }
    },
  },
})

export default newsFilterState
